import React, { useRef } from "react";
// import * as request from "../../../../Common/Util/HTTPRequest";
import "./index.scss";

function Base(props) {
  const { id, title, children, warning } = props;
  return (
    <div className={`field field-${id}`}>
      <div className="field_title">{title}</div>
      <div className="field_body">{children}</div>
      <div className="field_warning">{warning}</div>
    </div>
  );
}

function Input(props) {
  const { id, type = "text", placeholder, value, onChange, fixed } = props;
  return (
    <Base {...props}>
      {fixed ? (
        value || "-"
      ) : (
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={e => {
            if (onChange) onChange(e.currentTarget.value, id);
          }}
        />
      )}
    </Base>
  );
}

function Textarea(props) {
  const { id, placeholder, value, onChange } = props;
  return (
    <Base {...props}>
      <textarea
        placeholder={placeholder}
        value={value}
        onChange={e => {
          if (onChange) onChange(e.currentTarget.value, id);
        }}
      />
    </Base>
  );
}

function Select(props) {
  const { id, value, options, onChange } = props;
  return (
    <Base {...props}>
      <select
        value={value}
        onChange={e => {
          if (onChange) onChange(e.currentTarget.value, id);
        }}
      >
        {options.map((option, id) => (
          <option key={id} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </Base>
  );
}

function File(props) {
  const { id, accept, value, onChange, fixed } = props;
  const fileInputRef = useRef();
  return (
    <Base {...props}>
      {/* <img className="file_image" src={URL.S3_ETC + "/" + value} alt={value} /> */}
      <input
        id={id}
        ref={fileInputRef}
        type="file"
        accept={accept}
        onChange={e => {
          // const selectedFile = e.target.files[0];
          // if (!selectedFile) return;
          // const data = new FormData();
          // data.append("file", selectedFile);
          // request
          //   .dreamUpload(data)
          //   .then(res => res.json())
          //   .then(json => {
          //     if (json.success) {
          //       onChange(json.key, id);
          //     } else {
          //       throw json;
          //     }
          //   })
          //   .catch(err => {
          //     alert(JSON.stringify(err));
          //   });
        }}
        hidden
      />
      {fixed || (
        <button
          className="file_button"
          onClick={() => {
            fileInputRef.current.click();
          }}
        >
          업로드
        </button>
      )}
    </Base>
  );
}

function OnOff(props) {
  const { id, value: isOn, onChange, fixed } = props;
  return (
    <Base {...props}>
      <div
        className={`onoff onoff-${isOn ? "on" : "off"}${
          fixed ? " onoff-disabled" : ""
        }`}
      >
        <div
          className="onoff_background"
          onClick={() => {
            if (fixed) return;
            if (onChange) onChange(!isOn, id);
          }}
        >
          <div className="onoff_thumb" />
        </div>
      </div>
    </Base>
  );
}

const Field = {
  Base,
  Input,
  Textarea,
  Select,
  File,
  OnOff
};
export default Field;
