import React, { Component } from "react";
import { connect } from "react-redux";
import { withLoginInfo } from "../../Common/Util/LoginManager";

import View from "./View.js";
import { withRouter } from "../../Common/Util/withRouter.js";

class Container extends Component {
  componentDidMount() {
    this.checkLoggedOut();
  }
  componentDidUpdate() {
    this.checkLoggedOut();
  }
  checkLoggedOut() {
    const { isLoggedIn, navigate } = this.props;
    if (!isLoggedIn) navigate("/");
  }

  onClickLogout = () => {
    this.props.logout();
  };
  onClickHome = () => {
    const { user } = this.props;
    this.props.navigate(`/dashboard/${user.type}/home`);
  };
  onClickEnterpriseHome = () => {
    this.props.navigate("/dashboard/enterprise/home");
  };
  onClickEnterpriseSchools = () => {
    this.props.navigate("/dashboard/enterprise/schools");
  };

  render() {
    if (this.props.isLoggedIn) {
      return (
        <View
          onClickLogout={this.onClickLogout}
          onClickHome={this.onClickHome}
          onClickEnterpriseHome={this.onClickEnterpriseHome}
          onClickEnterpriseSchools={this.onClickEnterpriseSchools}
        />
      );
    } else {
      return <></>;
    }
  }
}

export default connect(state => ({ user: state.user }))(
  withRouter(withLoginInfo(Container))
);
