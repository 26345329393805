import React, { Component } from "react";
import { alert } from "./Alert";
import { confirm } from "./Confirm";
import { prompt } from "./Prompt";
import { flash } from "./Flash";
import { classInfo } from "./ClassInfo";
import { IntlWrapper } from "../../Locale";
// import ReactDOM from 'react-dom';
// import { ReactDOM } from "react-dom/client";
import { createRoot } from "react-dom/client";

import closeImg from "../../Image/popup_close.svg";
import "./index.scss";

let popUpRoot = null;
let isUnmounted = false;

function show(component, options) {
  // dismiss();
  // disableBodyScroll();

  const popUpElement = getPopUpElement();
  if(!popUpRoot || isUnmounted){
    popUpRoot = createRoot(popUpElement);
    isUnmounted = false;
  }

  const popUpContainer = (
    <IntlWrapper>
      <Container {...options}>{component}</Container>
    </IntlWrapper>
  );

  popUpRoot.render(popUpContainer);
  // ReactDOM.render(popUpContainer, popUpElement);
}
function dismiss() {
  if(popUpRoot && !isUnmounted){
    popUpRoot.unmount();
    isUnmounted = true;
  }
  // const popUpElement = getPopUpElement();
  // ReactDOM.render(null, popUpElement);
  enableBodyScroll();
}

function disableBodyScroll() {
  document.body.classList.remove("body-unsrollable");
}
function enableBodyScroll() {
  document.body.classList.add("body-unsrollable");
}

function getPopUpElement() {
  let popUpElement = document.getElementById("popup");

  if (!popUpElement) {
    popUpElement = document.createElement("div");
    popUpElement.setAttribute("id", "popup");
    document.getElementById("root").appendChild(popUpElement);
  }

  return popUpElement;
}

class Container extends Component {
  onClickClose = () => {
    dismiss();
  };
  render() {
    const {
      children,
      closeButton = false,
      dismissOverlay = true,
      allowOverflow = false
    } = this.props;
    return (
      <div className="popup_container">
        <div
          className="popup_overlay"
          onClick={dismissOverlay ? dismiss : undefined}
        />
        <div
          className={`popup_content${
            allowOverflow ? " popup_content-allowOverflow" : ""
          }`}
        >
          {closeButton && (
            <div className="popup_content_close" onClick={this.onClickClose}>
              <img src={closeImg} alt="close" />
            </div>
          )}
          {children}
        </div>
      </div>
    );
  }
}

const PopUp = {
  alert,
  confirm,
  prompt,
  flash,
  show,
  dismiss,
  classInfo,
};
export default PopUp;
