import React from "react";
import moment from "moment";
import DataTable from "../../Common/Component/DataTable";
import "./index.scss";
import { type } from "@testing-library/user-event/dist/type";

function View(props) {
  const { total } = props;
  return (
    <div className="logs">
      <div className="dashboard_content_title">
        로그 <span>({total}개)</span>
      </div>
      <Table {...props} />
    </div>
  );
}
export default View;

function Table(props) {
  const { logs } = props;
  let count = 0;
  const data = logs.map(log => (
    count = count + 1,
    {
      id: log.id,
      name: log.user.name,
      startTime: moment(log.usageStartTime).format("YY-MM-DDTHH:mm:ss"),
      endTime: moment(log.usageEndTime).format("YY-MM-DDTHH:mm:ss"),
      count: count
  }));

  const columns = [
    { name: "count", label: "번호" },
    // {
    //   name: "loginId",
    //   label: "계정 아이디",
    //   options: {
    //     customBodyRenderLite: i => (
    //       <span
    //         className="loginId"
    //         onClick={() => {
    //           onClickStudent(logs[i]);
    //         }}
    //       >
    //         {logs[i].user.loginId}
    //       </span>
    //     )
    //   }
    // },
    { name: "name", label: "이름" },
    { name: "startTime", label: "시작시간"},
    { name: "endTime", label: "종료시간"},
    // { 
    //   name: "type", 
    //   label: "구분",
    //   options: {
    //     customBodyRenderLite: i => {
    //       if(logs[i].user.type == true){
    //         return <span>프로</span>
    //       }
    //       else{
    //         return <span>일반</span>
    //       }
    //     }
    //   }
    // },
    // { 
    //   name: "gender", 
    //   label: "성별",
    //   options: {
    //     customBodyRenderLite: i => {
    //       if(logs[i].user.gender == true){
    //         return <span>남자</span>
    //       }
    //       else{
    //         return <span>여자</span>
    //       }
    //     }
    //   }
    // },
    // { name: "createdAt", label: "가입일" }
  ];
  const options = {
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    textLabels: { body: { noMatch: "-" } }
  };

  return (
    <div className="logs_table">
      <DataTable data={data} columns={columns} options={options} />
    </div>
  );
}
