import React from "react";
import Field from "../../Common/Component/Field";
import "./index.scss";

function View(props) {
  const {
    loginId,
    name,
    gender,
    createdAt,
    isApprove,
    requestedBy,
    onChangeField,
    onClickEdit,
    onClickCancel
  } = props;

  const isApproveString = isApprove == true? "승인" : isApprove == false? "거절" : "대기";
  const requestedByString = requestedBy == true? "학생" : "코치";
  const options = [
    { value: '승인', label: '승인' },
    { value: '거절', label: '거절' },
    { value: '대기', label: '대기' },
  ];

  const showGender = gender === true? "남자": "여자";
  return (
    <div className="student">
      <div className="dashboard_content_title">레슨 상세정보</div>
      <div className="student_fields">
        <Field.Input id="loginId" title="아이디" value={loginId} fixed />
        <Field.Input id="name" title="이름" value={name} fixed/>
        <Field.Input id="gender" title="성별" value={showGender} fixed/>
        <Field.Input id="createdAt" title="신청일시" value={createdAt} fixed />
        <Field.Input id="requestedBy" title="신청자" value={requestedByString} fixed />
        <Field.Select 
          id="isApprove" 
          title="상태" 
          value={isApproveString}
          options={options}
          onChange={onChangeField}
        />
        <button className='btn_edit' onClick={onClickEdit}>수정하기</button>
        <button className='btn_cancel' onClick={onClickCancel}>돌아가기</button>
      </div>
    </div>
  );
}
export default View;
