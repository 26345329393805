import React, { Component } from "react";
import QueryString from "query-string";
import * as request from "../../Common/Util/HTTPRequest";
import View from "./View";
import "./index.scss";
import { withRouter } from "../../Common/Util/withRouter";
import PopUp from "../../Common/PopUp";

class PadLogs extends Component {
  constructor(props) {
    super(props);
    this.state = { total: 0, logs: [] };
  }
  componentDidMount() {
    this.loadLogs();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      this.loadLogs();
    }
  }

  loadLogs() {
    const { user } = this.props;
    request
      .getLogs(user.id, user.institution? user.institution.id: null)
      .then(res => res.json())
      .then(json => {
        if (json.success) {
          const { total, logs } = json.data;
          this.setState({ total, logs });
        }
        else {
          throw json;
        }
      })
      .catch(err => {
        this.setState({ total: 0, logs: [] });
        console.error(err);
        if(err.message === "Unauthorized"){
          PopUp.alert({title: "다시 로그인 해주세요."});
          const { navigate } = this.props;
          sessionStorage.removeItem("loginToken");
          navigate("/");
        }
      });
  }

  // onClickStudent = student => {
  //   const { navigate, location } = this.props;
  //   const { search, pathname } = location;
  //   const query = QueryString.parse(search);
  //   query.studentId = student.id;
  //   const queryString = QueryString.stringify(query);
  //   navigate(pathname + "?" + queryString);
  // };

  render() {
    const { total, logs } = this.state;
    return (
      <View
        total={total}
        logs={logs}
        // onClickStudent={this.onClickStudent}
      />
    );
  }
}

export default withRouter(PadLogs);
