import React, { Component } from "react";
import QueryString from "query-string";
import * as request from "../../Common/Util/HTTPRequest";
import PopUp from "../../Common/PopUp";
import View from "./View";
import "./index.scss";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Lesson from "../Lesson";

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      lessons: []
    };
  }
  componentDidMount() {
    this.loadInstructorLessons(this.props.instructorId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      this.loadInstructorLessons(this.props.instructorId);
    }
  }

  loadInstructorLessons(id) {
    const { user } = this.props;

    request
      .getInstructorLessons(this.props.user.id, id, user.type === "institution"? user.institution.id: null)
      .then(res => res.json())
      .then(json => {
        if (json.success) {
          const { total, lessons } = json.data;
          this.setState({ total, lessons });
        } else {
          throw json;
        }
      })
      .catch(err => {
        this.setState({ instructor: null });
        if(err.message === "Unauthorized"){
          PopUp.alert({title: "다시 로그인 해주세요."});
          const { navigate } = this.props;
          sessionStorage.removeItem("loginToken");
          navigate("/");
        }
      });
  }

  onClickEdit = lesson => {
    const { navigate, location } = this.props;
    const { search, pathname } = location;
    const query = QueryString.parse(search);
    query.lessonId = lesson.id;
    const queryString = QueryString.stringify(query);
    navigate(pathname + "?" + queryString);
  };

  render() {
    const { total, lessons } = this.state;
    const { location } = this.props;
    const { lessonId } = QueryString.parse(location.search);
    if(lessonId){
      return <Lesson {...this.props} lessonId={lessonId}/>
    }
    else{
      return (
        <View
          total={total}
          lessons={lessons}
          onClickEdit={this.onClickEdit}
        />
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(Container);

// export default Container;
