import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from "./Page/Login";
import { withLoginTry } from "./Common/Util/LoginManager";
import Dashboard from "./Page/Dashboard";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login/>}/>
        <Route
          exact 
          path="/dashboard/:mode/:menu"
          element={<Dashboard/>}
        />
        <Route
          exact 
          path="/dashboard/:mode/:semesterId/:menu"
          element={<Dashboard/>}
        />
      </Routes>
    </Router>
  );
}

export default withLoginTry(App);
