import React, { Component } from "react";
import QueryString from "query-string";
import * as request from "../../Common/Util/HTTPRequest";
import Instructor from "../Instructor";
import View from "./View";
import "./index.scss";
import { withRouter } from "../../Common/Util/withRouter";
import PopUp from "../../Common/PopUp";
import Lessons from "../Lessons";

class Instructors extends Component {
  constructor(props) {
    super(props);
    this.state = { total: 0, instructors: []};
  }
  componentDidMount() {
    this.loadInstructors();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      this.loadInstructors();
    }
  }

  loadInstructors() {
    const { user } = this.props;
    request
      .getInstructors(user.id, user.institution? user.institution.id: null)
      .then(res => res.json())
      .then(json => {
        if (json.success) {
          const { total, instructors } = json.data;
          this.setState({ total, instructors });
        } else {
          throw json;
        }
      })
      .catch(err => {
        this.setState({ total: 0, instructors: [] });
        console.error(err);
          if(err.message === "Unauthorized"){
            PopUp.alert({title: "다시 로그인 해주세요."});
            const { navigate } = this.props;
            sessionStorage.removeItem("loginToken");
            navigate("/");
          }
      });
  }

  onClickInstructor = instructor => {
    const { navigate, location } = this.props;
    const { search, pathname } = location;
    const query = QueryString.parse(search);
    query.instructorId = instructor.id;
    query.isLesson = false;
    const queryString = QueryString.stringify(query);
    navigate(pathname + "?" + queryString);
  };

  onClickInstructorLesson = instructor => {
    const { navigate, location } = this.props;
    const { search, pathname } = location;
    const query = QueryString.parse(search);
    query.instructorId = instructor.id;
    query.isLesson = true;
    const queryString = QueryString.stringify(query);
    navigate(pathname + "?" + queryString);
  };

  render() {
    const { location } = this.props;
    const { instructorId, isLesson } = QueryString.parse(location.search);
    const isLessonBool = isLesson === "true";

    if (instructorId && isLessonBool === false) {
      return <Instructor {...this.props} instructorId={instructorId} isLesson={isLesson}/>;
    } 
    else if(instructorId && isLessonBool === true){
      return <Lessons {...this.props} instructorId={instructorId} isLesson={isLesson}/>;
    }
    else {
      const { total, instructors } = this.state;
      return (
        <View
          total={total}
          instructors={instructors}
          onClickInstructor={this.onClickInstructor}
          onClickInstructorLesson={this.onClickInstructorLesson}
        />
      );
    }
  }
}

export default withRouter(Instructors);
