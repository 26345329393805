import React from "react";
import moment from "moment";
import DataTable from "../../Common/Component/DataTable";
import "./index.scss";
import { type } from "@testing-library/user-event/dist/type";

function View(props) {
  const { total } = props;
  return (
    <div className="students">
      <div className="dashboard_content_title">
        회원 관리하기 <span>({total}명)</span>
      </div>
      <Table {...props} />
    </div>
  );
}
export default View;

function Table(props) {
  const { students, onClickStudent } = props;

  let count = 0;
  const data = students.map(student => (
    count = count + 1,
    {
    id: student.id,
    loginId: student.user.loginId,
    name: student.user.name,
    gender: student.user.gender,
    type: student.user.type,
    createdAt: moment(student.user.createdAt).format("YYYY-MM-DD"),
    count: count
  }));

  const columns = [
    { name: "count", label: "번호" },
    {
      name: "loginId",
      label: "계정 아이디",
      options: {
        customBodyRenderLite: i => (
          <span
            className="loginId"
            onClick={() => {
              onClickStudent(students[i]);
            }}
          >
            {students[i].user.loginId}
          </span>
        )
      }
    },
    { name: "name", label: "이름" },
    { 
      name: "type", 
      label: "구분",
      options: {
        customBodyRenderLite: i => {
          if(students[i].user.type == true){
            return <span>프로</span>
          }
          else{
            return <span>일반</span>
          }
        }
      }
    },
    { 
      name: "gender", 
      label: "성별",
      options: {
        customBodyRenderLite: i => {
          if(students[i].user.gender == true){
            return <span>남자</span>
          }
          else{
            return <span>여자</span>
          }
        }
      }
    },
    { name: "createdAt", label: "가입일" }
  ];
  const options = {
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    textLabels: { body: { noMatch: "-" } }
  };

  return (
    <div className="students_table">
      <DataTable data={data} columns={columns} options={options} />
    </div>
  );
}
