// import React from "react";
// import "./index.scss";
// import ReactApexChart from 'react-apexcharts';

// function View(props) {
//   const { total, swings } = props;

//   const data = swings.map(swing => {

//   })

//   const series = [
//     {
//       name: 'box',
//       type: 'boxPlot',
//       data: [
//         {
//           x: new Date('2017-01-01').getTime(),
//           y: [54, 66, 69, 75, 88]
//         },
//         {
//           x: new Date('2018-01-01').getTime(),
//           y: [43, 65, 69, 76, 81]
//         },
//         {
//           x: new Date('2019-01-01').getTime(),
//           y: [31, 39, 45, 51, 59]
//         },
//         {
//           x: new Date('2020-01-01').getTime(),
//           y: [39, 46, 55, 65, 71]
//         },
//         {
//           x: new Date('2021-01-01').getTime(),
//           y: [29, 31, 35, 39, 44]
//         }
//       ]
//     },
//     {
//       name: 'outliers',
//       type: 'scatter',
//       data: [
//         {
//           x: new Date('2017-01-01').getTime(),
//           y: 32
//         },
//         {
//           x: new Date('2018-01-01').getTime(),
//           y: 25
//         },
//         {
//           x: new Date('2019-01-01').getTime(),
//           y: 64
//         },
//         {
//           x: new Date('2020-01-01').getTime(),
//           y: 27
//         },
//         {
//           x: new Date('2020-01-01').getTime(),
//           y: 78
//         },
//         {
//           x: new Date('2021-01-01').getTime(),
//           y: 15
//         }
//       ]
//     }
//   ]
//   const options = {
//     chart: {
//       type: 'boxPlot',
//       height: 350
//     },
//     colors: ['#008FFB', '#FEB019'],
//     title: {
//       text: '프로',
//       align: 'left'
//     },
//     xaxis: {
//       type: 'datetime',
//       tooltip: {
//         formatter: function(val) {
//           return new Date(val).getFullYear()
//         }
//       }
//     },
//     tooltip: {
//       shared: false,
//       intersect: true
//     }
//   }

//   return (
//     <div className="swings">
//       <div className="dashboard_content_title">
//         스윙 요약 <span>({total}개)</span>
//       </div>
//       <ReactApexChart 
//         options={options} 
//         series={series} 
//         type="boxPlot" 
//         height={350}
//       />
//     </div>
//   );
// }
// export default View;









import React from "react";
import "./index.scss";
import Plot from 'react-plotly.js';

function View(props) {
  const { total, swings } = props;

  let proDatas = [
    {
      name: "down-swing Rwrist angle",
      data: []
    },
    {
      name: "address spine angle",
      data: []
    },
    {
      name: "back-swing spine angle",
      data: []
    },
    {
      name: "back-swing spine angle min-max",
      data: []
    },
    {
      name: "donw-swing spine angle",
      data: []
    },
    {
      name: "down-swing spine angle min-max",
      data: []
    },
    {
      name: "total spine angle",
      data: []
    },
    {
      name: "total spine angle min-max",
      data: []
    }
  ];
  swings.map(swing => {
    if(swing.id !== 406){
      const json = JSON.parse(swing.dataJSON).data
      proDatas[0].data.push(json.path.rwrist.down_swing_angle);
      proDatas[1].data.push(json.rotation_angle.spine_angle.address);
      proDatas[2].data.push(json.rotation_angle.spine_angle.back);
      proDatas[3].data.push(json.rotation_angle.spine_angle.back_minmax);
      proDatas[4].data.push(json.rotation_angle.spine_angle.down);
      proDatas[5].data.push(json.rotation_angle.spine_angle.down_minmax);
      proDatas[6].data.push(json.rotation_angle.spine_angle.total);
      proDatas[7].data.push(json.rotation_angle.spine_angle.total_minmax);
    }
  });

  // const data = [1, 4, 6, 23, 23, 20, 2.0, 2.1, 3.3, -4, 10.3];
  let trace = [];
  proDatas.map(proData => {
    trace.push({
      y: proData.data,
      type: 'box',
      boxpoints: 'outliers', // 아웃라이어 표시
      jitter: 0.5,
      pointpos: -1.8,
      name: proData.name,
      hoverinfo: 'y'
    });
  })

  // const trace = {
  //   y: data,
  //   type: 'box',
  //   // boxpoints: 'all', // 아웃라이어 표시
  //   jitter: 0.5,
  //   pointpos: -1.8,
  //   name: "a",
  //   hoverinfo: 'y'
  // };


  return (
    <div className="swings">
      <div className="dashboard_content_title">
        기기 사용 요약
      </div>
      <div className="dashboard_content_graph">
        <Plot
          data={trace}
          layout={{ title: '누적', width: 1500}}
        />
      </div>
      
    </div>
  );
}
export default View;




