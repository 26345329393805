import React, { Component } from "react";
import moment from "moment";
import * as request from "../../Common/Util/HTTPRequest";
import PopUp from "../../Common/PopUp";
import View from "./View";
import "./index.scss";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginId: "",
      name: "",
      gender: true,
      certificate: "",
      introduce: "",
      createdAt: "",
      user: {
        id: 0,
        type: ""
      }
    };
  }
  componentDidMount() {
    this.loadInstructor(this.props.instructorId);
  }

  loadInstructor(id) {
    const { user } = this.props;
    //
    request
      .getInstructor(this.props.user.id, id, user.institution? user.institution.id: null)
      .then(res => res.json())
      .then(json => {
        if (json.success) {
          const instructor = {
            loginId: json.data.instructor.user.loginId,
            password: "****",
            name: json.data.instructor.user.name,
            gender: json.data.instructor.user.gender,
            createdAt: moment(json.data.instructor.user.createdAt).format(
              "YYYY-MM-DD HH:MM"
            ),
            certificate: json.data.instructor.certificate,
            introduce: json.data.instructor.introduce,
            gender: json.data.instructor.user.gender
          };
          this.instructor = instructor;
          this.setState({ ...instructor });
        } else {
          throw json;
        }
      })
      .catch(err => {
        this.setState({ instructor: null });
          if(err.message === "Unauthorized"){
            PopUp.alert({title: "다시 로그인 해주세요."});
            const { navigate } = this.props;
            sessionStorage.removeItem("loginToken");
            navigate("/");
          }
      });
  }
  updateInstructor(id, values) {
    request
      .updateInstructor(this.props.user.id, id, values)
      .then(res => res.json())
      .then(json => {
        if (json.success && json.data) {
          this.instructor = { ...this.instructor, values };
          PopUp.alert({ title: "수정했습니다." });

          const { navigate } = this.props;
          navigate('/dashboard/master/instructors');
        } else {
          throw json;
        }
      })
      .catch(err => {
        console.error(err);
          if(err.message === "Unauthorized"){
            PopUp.alert({title: "다시 로그인 해주세요."});
            const { navigate } = this.props;
            sessionStorage.removeItem("loginToken");
            navigate("/");
          }
      });
  }

  onChangeField = (value, id) => {
    this.setState({ [id]: value });
  };

  onClickEdit = () => {
    this.updateInstructor(this.props.instructorId, this.getUpdated());
  };

  onClickCancel = () => {
    const { navigate } = this.props;
    navigate(-1);
  }
  
  getUpdated() {
    const updated = {};
    const {
      password,
      name,
      gender,
      certificate,
      introduce
    } = this.state;
    const values = { password, name, gender, certificate, introduce };
    for (let key in values) {
      if (values[key] !== this.instructor[key]) {
        if(key == "gender"){
          updated[key] = values[key] === 'true';
          continue;
        }
        updated[key] = values[key];
      }
    }
    return updated;
  }

  render() {
    return (
      <View
        {...this.state}
        onChangeField={this.onChangeField}
        onClickEdit={this.onClickEdit}
        onClickCancel={this.onClickCancel}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(Container);

// export default Container;
