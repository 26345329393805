import React, { Component } from "react";
import { withRouter } from "../../Common/Util/withRouter.js";
import { withLoginInfo } from "../../Common/Util/LoginManager";
import Validate from "../../Common/Util/Validate";
import View from "./View.js";

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = { loginId: "", password: "", warning: "" , auth: false};
  }
  componentDidMount() {
    this.checkLoggedIn();
  }
  componentDidUpdate() {
    this.checkLoggedIn();
  }
  checkLoggedIn() {
    const { isLoggedIn, user, navigate, location } = this.props;
    const { auth } = this.state;
    
    if(auth == false) return;
    
    if (isLoggedIn) {
      switch (user.type) {
        case "master":
          navigate("/dashboard/master/MasterHome");
          break;
        case "institution":
          navigate("/dashboard/institution/home");
          break;
        default:
          this.setState({
            warning: "잘못된 사용자 권한입니다. 관리자에게 문의해주세요."
          });
          break;
      }
    }
  }

  onChangeInput = e => {
    const { id, value } = e.currentTarget;
    this.setState({ [id]: value });
    this.hideWarning();
  };
  onClickLogin = () => {
    const { loginId, password } = this.state;

    const loginIdValidation = Validate.loginId(loginId);
    if (!loginIdValidation.result) {
      this.showWarning();
      return;
    }
    const passwordValidation = Validate.password(password);
    if (!passwordValidation.result) {
      this.showWarning();
      return;
    }
    const params = { loginId, password };
    this.props.login(params, {
      onerror: () => {
        this.showWarning();
      },
      onsuccess: () => {
        this.setState({ auth: true })
      }
    });
  };

  showWarning() {
    this.setState({ warning: "아아디나 비밀번호가 맞는지 확인해주세요" });
  }
  hideWarning() {
    this.setState({ warning: "" });
  }

  render() {
    const { loginId, password, warning } = this.state;
    return (
      <View
        loginId={loginId}
        password={password}
        warning={warning}
        onChangeInput={this.onChangeInput}
        onClickLogin={this.onClickLogin}
      />
    );
  }
}

export default withRouter(withLoginInfo(Container));
