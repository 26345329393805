import React, { Component } from "react";
import { injectIntl } from "react-intl";
import PopUp from "../";
import "./index.scss";

import logoImg from "../../../Image/logo.svg";

const Flash = injectIntl(
  class extends Component {
    componentDidMount() {
      this.timer = setTimeout(() => {
        PopUp.dismiss();
        if (this.props.onTimeout) this.props.onTimeout();
      }, this.props.timeout);
    }
    componentWillUnmount() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
    }
    render() {
      const { formatMessage } = this.props.intl;
      const {
        title = formatMessage({ id: "ID_POPUP_DEFAULT_FLASH_TITLE" })
      } = this.props;
      return (
        <div className="popup_flash">
          <img className="popup_flash_logo" src={logoImg} alt="logo" />
          <div className="popup_title">{title}</div>
        </div>
      );
    }
  }
);

export function flash(config = {}, options) {
  const { title, timeout = 600, onTimeout } = config;
  PopUp.show(<Flash title={title} timeout={timeout} onTimeout={onTimeout} />, {
    ...options,
    dismissOverlay: false
  });
}
