const url = process.env.REACT_APP_API_URL;

function createHeaders() {
  const headers = {};

  headers["Content-Type"] = "application/json; charset=utf-8";

  const loginToken = sessionStorage.getItem("loginToken");
  if (loginToken) {
    headers["Authorization"] = loginToken;
  }

  return headers;
}
function _fetch(path, method, params) {
  return fetch(url + path, {
    method: method,
    headers: createHeaders(),
    body: params && JSON.stringify(params)
  });
}

const METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE"
};
function get(path) {
  return _fetch(path, METHOD.GET);
}
function post(path, params) {
  return _fetch(path, METHOD.POST, params);
}
function put(path, params) {
  return _fetch(path, METHOD.PUT, params);
}
function _delete(path, params) {
  return _fetch(path, METHOD.DELETE, params);
}


export const login = params => {
  return post("admin/login", params);
};
export const loginByToken = params => {
  return post("admin/loginByToken", params);
};

export const getInstructor = (adminId, instructorId, institutionId = null) => {
  if(institutionId === null) return get(`admin/${adminId}/instructor/${instructorId}`);
  else return get(`admin/${adminId}/institution/${institutionId}/instructor/${instructorId}`);
};
export const getInstructors = (adminId, institutionId = null) => {
  if(institutionId === null) return get(`admin/${adminId}/instructors`);
  else return get(`admin/${adminId}/institution/${institutionId}/instructors`);
};
export const updateInstructor = (adminId, instructorId, params) => {
  return put(`admin/${adminId}/instructor/${instructorId}`, params);
};

export const getStudent = (adminId, studentId) => {
  return get(`admin/${adminId}/student/${studentId}`);
};
export const updateStudent = (adminId, studentId, params) => {
  return put(`admin/${adminId}/student/${studentId}`, params);
};
export const getStudents = (adminId, take) => {
  return get(`admin/${adminId}/students?take=${take}`);
};

export const getInstructorLessons = (adminId, instructorId, institutionId = null) => {
  if(institutionId === null )return get(`admin/${adminId}/instructor/${instructorId}/lesson`);
  else return get(`admin/${adminId}/institution/${institutionId}/instructor/${instructorId}/lesson`);
};

export const getInstructorLesson = (adminId, instructorId, lessonId, institutionId = null) => {
  if(institutionId === null) return get(`admin/${adminId}/instructor/${instructorId}/lesson/${lessonId}`);
  else return get(`admin/${adminId}/institution/${institutionId}/instructor/${instructorId}/lesson/${lessonId}`);
};
export const updateLesson = (adminId, lessonId, params) => {
  return put(`admin/${adminId}/lesson/${lessonId}`, params);
};

export const getSwings = (adminId, take) => {
  return get(`admin/${adminId}/myswings`);
};
export const deleteSwings = (adminId, params) => {
  return _delete(`admin/${adminId}/myswings`, params);
};


export const postUser = (adminId, params) => {
  return post(`admin/${adminId}/user`, params);
};

export const postInstitution = (adminId, params) => {
  return post(`admin/${adminId}/institution`, params);
};

export const getInstitutions = (adminId, take) => {
  return get(`admin/${adminId}/institutions?take=${take}`);
};

export const getInstitution = (adminId, institutionId) => {
  return get(`admin/${adminId}/institution/${institutionId}`);
};
export const updateInstitution = (adminId, institutionId, params) => {
  return put(`admin/${adminId}/institution/${institutionId}`, params);
};

export const getLogs = (adminId, institutionId) => {
  return get(`admin/${adminId}/institution/${institutionId}/logs`);
};
///////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const postAdmin = params => {
  return post(`/admin/admin`, params);
};
export const deleteAdmin = adminId => {
  return _delete(`/admin/admin/${adminId}`);
};
export const changePassword = (adminId, params) => {
  return put(`/admin/admin/${adminId}/password`, params);
};

export const sms = params => {
  return post("/admin/sms", params);
};
export const getSmsUsageHistory = id => {
  return get(`/admin/smsUsageHistory/${id}`);
};
export const getSchoolSmsUsageHistories = schoolId => {
  return get(`/admin/school/${schoolId}/smsUsageHistories`);
};

export const getEnterpriseTutors = enterpriseId => {
  return get(`/admin/enterprise/${enterpriseId}/tutors`);
};


export const getTutorClassGroups = tutorId => {
  return get(`/admin/tutor/${tutorId}/classGroups`);
};

export const getSchoolAdmins = schoolId => {
  return get(`/admin/school/${schoolId}/admins`);
};

export const getSchoolStudents = schoolId => {
  return get(`/admin/school/${schoolId}/students`);
};

export const getSchoolNotices = (schoolId, queryParams) => {
  return get(
    `/admin/school/${schoolId}/notice?offset=${queryParams.offset}&limit=${queryParams.limit}`
  );
};
export const getSchoolNotice = (schoolId, noticeId) => {
  return get(`/admin/school/${schoolId}/notice/${noticeId}`);
};
export const postSchoolNotice = (schoolId, params) => {
  return post(`/admin/school/${schoolId}/notice`, params);
};
export const updateSchoolNotice = (schoolId, noticeId, params) => {
  return put(`/admin/school/${schoolId}/notice/${noticeId}`, params);
};
export const deleteSchoolNotice = (schoolId, noticeId) => {
  return put(`/admin/school/${schoolId}/notice/${noticeId}/delete`);
};

export const getSchoolTutors = schoolId => {
  return get(`/admin/school/${schoolId}/tutors`);
};

export const getSemesterTutors = semesterId => {
  return get(`/admin/semester/${semesterId}/tutors`);
};

export const putSemesterRecruits = semesterId => {
  return put(`/admin/semester/${semesterId}/semesterRecruits`);
}

export const getSchoolClassGroupsInProcess = schoolId => {
  return get(`/admin/school/${schoolId}/classGroupsInProcess`);
};

export const getSchoolGroupSchedulesInProcess = schoolId => {
  return get(`/admin/school/${schoolId}/groupSchedulesInProcess`);
};

export const getSemesters = schoolId => {
  // return get(`/admin/semesters/${params.schoolId}`);
  return get(`/admin/school/${schoolId}/semesters`);
};
export const postSchoolSemester = (schoolId, params) => {
  return post(`/admin/school/${schoolId}/semester`, params);
};
export const putSemester = (semesterId, params) => {
  return put(`/admin/semester/${semesterId}`, params);
};
export const updateRecruits = (semesterId, recruits) => {
  return put(`/admin/semester/${semesterId}/recruits`, recruits);
};

export const getSemesterLectures = semesterId => {
  return get(`/admin/semester/${semesterId}/lectures`);
};
export const getLecture = lectureId => {
  return get(`/admin/lecture/${lectureId}`);
};
export const editLecture = (lectureId, params) => {
  return put(`/admin/lecture/${lectureId}`, params);
};
export const putLecturePermission = (lectureId, params) => {
  return put(`/admin/lecture/${lectureId}/status/permission`, params);
};
export const rejectLecture = lectureId => {
  return put(`/admin/lecture/${lectureId}/status/reject`);
};

export const getSemesterGroups = (semesterId, includeSurveys = false) => {
  return get(`/admin/semester/${semesterId}/Groups?includeSurveys=${includeSurveys}`);
};
export const getLectureClassGroups = lectureId => {
  return get(`/admin/lecture/${lectureId}/classGroups`);
};

export const getClassGroup = classGroupId => {
  return get(`/admin/classGroup/${classGroupId}`);
};

export const getClassGroupClasses = classGroupId => {
  return get(`/admin/classGroup/${classGroupId}/classes`);
};

export const updateMyClasses = params => {
  return put("/admin/myClasses", params);
};

export const getClassGroupStudents = classGroupId => {
  return get(`/admin/classGroup/${classGroupId}/students`);
};

export const getClassGroupStudentAttendances = classGroupId => {
  return get(`/admin/classGroup/${classGroupId}/attendances`);
};

export const getClassGroupQuizzes = classGroupId => {
  return get(`/admin/classGroup/${classGroupId}/quizzes`);
};

export const getClassGroupHomeworks = classGroupId => {
  return get(`/admin/classGroup/${classGroupId}/homeworks`);
};

export const cancelClassGroup = classGroupId => {
  return put(`/admin/classGroup/${classGroupId}/cancel`);
};

export const getQuizResult = quizId => {
  return get(`/admin/quiz/${quizId}/result`);
};

export const getHomeworkResult = homeworkId => {
  return get(`/admin/homework/${homeworkId}/result`);
};

export const getGroupApplies = groupId => {
  return get(`/admin/group/${groupId}/applies`);
};
export const putMyGroupApplies = params => {
  return put(`/admin/myGroupApplies/status`, params);
};
export const postMyGroupApplyForSchool = (schoolId, groupId, loginId) => {
  return post(
    `/admin/school/${schoolId}/group/${groupId}/myGroupApply`,
    { loginId }
  );
};

export const getSemesterApplies = (schoolId, semesterId) => {
  return get(`/admin/school/${schoolId}/semester/${semesterId}/myGroupApplies`);
};
export const getSemesterApplyCancels = semesterId => {
  return get(`/admin/semester/${semesterId}/myGroupApplyCancels`);
};
export const getMyGroupApplyCancel = myGroupId => {
  return get(`/admin/myGroupApplyCancel/${myGroupId}`);
};
export const updateMyGroupApplyStatusCancelApproved = params => {
  return put("/admin/myGroupApplyCancel/status", params);
};


export const getStudentGroupSchedules = (studentId, groupId) => {
  return get(`/admin/student/${studentId}/group/${groupId}/schedules`);
};

export const putStudentForSchool = (schoolId, params) => {
  return put(`/admin/school/${schoolId}/student`, params);
};

export const postStudentsForSchool = (schoolId, params) => {
  return post(`/admin/school/${schoolId}/students`, params);
};

export const changeStudentPassword = (schoolId, studentId, params) => {
  return put(`/admin/school/${schoolId}/student/${studentId}/password`, params);
};

export const deleteLectures = lectureId => {
  return put("/admin/lectures/delete", { lectureId });
};

export const getGeneralLectures = runningOnly => {
  return get(`/admin/general/lectures${runningOnly ? "?runningOnly=true" : ""}`);
};

export const getCategories = () => {
  return get("/admin/categories");
};

export const getPayments = () => {
  return get("/admin/payments");
};
export const cancelPayment = params => {
  return put("/admin/payment/cancel", params);
};

export const getEnterprises = () => {
  return get("/admin/enterprises");
};

export const getSchoolAndAcademy = () => {
  return get("/admin/schoolandacademy");
};

export const getGeneralClassesInProcess = () => {
  return get(`/admin/general/lecture/classes`);
};

export const getGroupSurveyResults = groupId => {
  return get(`/admin/group/${groupId}/myGroupSurveys`);
};
export const getSchoolSurvey = schoolId => {
  return get(`/admin/schoolSurvey/${schoolId}`);
};
export const postSchoolSurvey = params => {
  return post(`/admin/schoolSurvey`, params);
};
export const putSchoolSurvey = params => {
  return put(`/admin/schoolSurvey`, params);
};
export const putSurveyDateTime = (semesterId, params) => {
  return put(`/admin/semester/${semesterId}/surveyDateTime`, params);
};
export const getSemesterSurveys = semesterId => {
  return get(`/admin/semester/${semesterId}/schoolSurveys`);
};

// 강사관리하기 클래스 리스트 보기
export const getTutorLectures = tutorId => {
  return get(`/admin/tutor/${tutorId}/lectures`);
};

export const putLectureIsHidden = (lectureId, params) => {
  return put(`/admin/lecture/${lectureId}/isHidden`, params);
};

export const getEvents = (offset = 0, limit = 1000) => {
  return get(`/admin/events?offset=${offset}&limit=${limit}`);
};

export const getEvent = eventId => {
  return get(`/admin/events/${eventId}`);
};

export const postEvents = params => {
  return post(`/admin/events`, params);
};

export const putEvent = (eventId, params) => {
  return put(`/admin/events/${eventId}`, params);
};

export const getGeneralGroupsSettlement = queryString => {
  return get(`/admin/general/groups/settlement?${queryString}`);
};

export const getGeneralGroupSettlement = groupId => {
  return get(`/admin/general/groups/${groupId}/settlement`);
};

export const getReviews = queryString => {
  return get(`/admin/reviews?${queryString}`);
};

export const getReviewReports = queryString => {
  return get(`/admin/reviewReports?${queryString}`);
};

export const putReview = (reviewId, params) => {
  return put(`/admin/reviews/${reviewId}`, params);
};


// _better MainBanner
export const postMainBanner = params => {
  return post(`/admin/mainBanner`, params);
};

export const putMainBanner = (id, params) => {
  return put(`/admin/mainBanner/${id}`, params);
};

export const getMainBanner = id => {
  return get(`/admin/mainBanner/${id}`);
};

export const getMainBanners = () => {
  return get("/admin/mainBanner");
};

// _better MidBanner
export const postMidBanner = params => {
  return post(`/admin/midBanner`, params);
};

export const putMidBanner = (id, params) => {
  return put(`/admin/midBanner/${id}`, params);
};

export const getMidBanner = id => {
  return get(`/admin/mainBanner/${id}`);
};

export const getMidBanners = () => {
  return get("/admin/mainBanner");
};

// _better avenger
export const postAvenger = params => {
  return post(`/admin/avenger`, params);
};

export const putAvenger = (id, params) => {
  return put(`/admin/avenger/${id}`, params);
};

export const getAvenger = id => {
  return get(`/admin/avenger/${id}`);
};

export const getAvengers = () => {
  return get("/admin/avenger");
};

//_better center
export const getCenters = params => {
  return get(`/center`, params);
};

export const putCenter = (id, params) => {
  return put(`/center/${id}`, params);
};
