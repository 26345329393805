import React from 'react';
import "./index.scss";

function View({
  userState,
  warnings,
  onClickAdd,
  onChange
}) {
  return (
    <div className="add-user">
      <h2>계정 추가</h2>
      <section>
        <div className="title">
          분류
        </div>
        <div className="content">
          <label>
            <input
              type="radio"
              name="type"
              value="student"
              checked={userState.type === "student"}
              onChange={onChange}
            />
            일반
          </label>
          <label>
            <input
              type="radio"
              name="type"
              value="instructor"
              checked={userState.type === "instructor"}
              onChange={onChange}
            />
            코치
          </label>
          <label>
            <input
              type="radio"
              name="type"
              value="institution"
              checked={userState.type === "institution"}
              onChange={onChange}
            />
            기관
          </label>
        </div>
      </section>
      <section>
        <div className="title">
          아이디
        </div>
        <div className="content">
          <input
            type="text"
            onChange={onChange}
            name="loginId"
            value={userState.loginId}
            placeholder="로그인 ID를 입력하세요"
          />
        </div>
        <div className="field_warning">
          {warnings.loginId}
        </div>
      </section>
      <section>
        <div className="title">
          비밀번호
        </div>
        <div className="content">
          <input
            type="text"
            onChange={onChange}
            name="password"
            value={userState.password}
            placeholder="비밀번호를 입력하세요"
          />
        </div>
        <div className="field_warning">
          {warnings.password}
        </div>
      </section>
      {userState.type !== "institution" &&
        <section>
          <div className="title">
            이름
          </div>
          <div className="content">
            <input
              type="text"
              onChange={onChange}
              name="username"
              value={userState.username}
              placeholder="이름을 입력하세요"
            />
          </div>
          <div className="field_warning">
            {warnings.username}
          </div>
        </section>
      }
      {userState.type === "institution" &&
        <section>
          <div className="title">
            기관 이름
          </div>
          <div className="content">
            <input
              type="text"
              onChange={onChange}
              name="name"
              value={userState.name}
              placeholder="기관 이름을 입력하세요"
            />
          </div>
          <div className="field_warning">
            {warnings.name}
          </div>
        </section>
      }     
      { userState.type !== "institution" &&
        <section>
          <div className="title">
            닉네임
          </div>
          <div className="content">
            <input
              type="text"
              onChange={onChange}
              name="nickname"
              value={userState.nickname}
              placeholder="닉네임을 입력하세요"
            />
          </div>
          <div className="field_warning">
            {warnings.username}
          </div>
        </section>
      }
      { userState.type !== "institution" &&
        <section>
          <div className="title">
            성별
          </div>
          <div className="content">
            <label>
              <input
                type="radio"
                name="gender"
                value="F"
                checked={userState.gender === "F"}
                onChange={onChange}
              />
              여자
            </label>
            <label>
              <input
                type="radio"
                name="gender"
                value="M"
                checked={userState.gender === "M"}
                onChange={onChange}
              />
              남자
            </label>
          </div>
        </section>
      }
      {userState.type === 'student' && (
        <section>
          <div className="title">
            구분
          </div>
          <div className="content">
            <label>
              <input
                type="radio"
                name="dbType"
                value="일반"
                checked={userState.dbType === "일반"}
                onChange={onChange}
              />
              일반
            </label>
            <label>
              <input
                type="radio"
                name="dbType"
                value="프로"
                checked={userState.dbType === "프로"}
                onChange={onChange}
              />
              프로
            </label>
          </div>
        </section>
      )}
      {userState.type === 'instructor' && (
        <section>
          <div className="title">
            자격증
          </div>
          <div className="content">
            <input
              type="text"
              onChange={onChange}
              name="certificate"
              value={userState.certificate}
              placeholder="프로 자격증을 입력하세요"
            />
          </div>
        </section>
      )}
      {userState.type === 'instructor' && (
        <section>
          <div className="title">
            소개
          </div>
          <div className="content">
            <input
              type="text"
              onChange={onChange}
              name="introduce"
              value={userState.introduce}
              placeholder="자기소개를 입력하세요"
            />
          </div>
        </section>
      )}
      {userState.type === "institution" && 
        <section>
          <div className="title">
            기관 주소
          </div>
          <div className="content">
            <input
              type="text"
              onChange={onChange}
              name="address"
              value={userState.address}
              placeholder="주소를 입력하세요"
            />
          </div>
          <div className="field_warning">
            {warnings.address}
          </div>
        </section>
      }
      {userState.type === "institution" && 
        <section>
          <div className="title">
            대표자 이름
          </div>
          <div className="content">
            <input
              type="text"
              onChange={onChange}
              name="ceoName"
              value={userState.ceoName}
              placeholder="대표자 이름을 입력하세요"
            />
          </div>
          <div className="field_warning">
            {warnings.ceoName}
          </div>
        </section>
      }
      {userState.type === "institution" && 
        <section>
          <div className="title">
            사업자등록번호
          </div>
          <div className="content">
            <input
              type="number"
              onChange={onChange}
              name="businessNumber"
              value={userState.businessNumber}
              placeholder="사업자등록번호를 입력하세요"
            />
          </div>
          <div className="field_warning">
            {warnings.businessNumber}
          </div>
        </section>
      }
      {userState.type === "institution" && 
        <section>
          <div className="title">
            이메일
          </div>
          <div className="content">
            <input
              type="email"
              onChange={onChange}
              name="email"
              value={userState.email}
              placeholder="이메일을 입력하세요"
            />
          </div>
          <div className="field_warning">
            {warnings.email}
          </div>
        </section>
      }
      {userState.type === "institution" && 
        <section>
          <div className="title">
            전화번호
          </div>
          <div className="content">
            <input
              type="number"
              onChange={onChange}
              name="phoneNumber"
              value={userState.phoneNumber}
              placeholder="전화번호를 입력하세요"
            />
          </div>
          <div className="field_warning">
            {warnings.phoneNumber}
          </div>
        </section>
      }
      {userState.type === "institution" && 
        <section>
          <div className="title">
            시리얼키
          </div>
          <div className="content">
            <input
              type='number'
              onChange={onChange}
              name="maxSerialKeys"
              value={userState.maxSerialKeys}
              placeholder="갯수를 입력하세요"
            />
          </div>
          <div className="field_warning">
            {warnings.maxSerialKeys}
          </div>
        </section>
      }
      
      <button onClick={onClickAdd}>추가하기</button>
    </div>
  )
}

export default View;
