import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { icons } from "../../../Page/Dashboard/DashMap";
import PopUp from "../../PopUp";
import "./index.scss";
import { connect } from "react-redux";
import { withRouter } from "../../Util/withRouter";

class Navbar extends Component {
  onClickMenu = menu => {
    if (["sms"].includes(menu)) {
      PopUp.flash({ title: "준비중입니다." });
    } else {
      const { mode, navigate } = this.props;
      navigate(`/dashboard/${mode}/${menu}`);
    }
  };

  onClickSemesterMenu = menu => {
    if (
      [
        "openClass",
        "manageApplies",
        "manageCancels",
        "tutors",
        "classGroups",
        "surveyForm",
        "surveys"
      ].includes(menu)
    ) {
      const { navigate, mode, semester } = this.props;
      navigate(`/dashboard/${mode}/${semester.id}/${menu}`);
    } else {
      PopUp.flash({ title: "준비중입니다." });
    }
  };

  render() {
    const { user, menus } = this.props;
    const { mode, menu, semesterId } = this.props.params;
    return (
      <div className={`dashboard_navbar dashboard_navbar-${mode}`}>
        {menus.map(_menu => {
          return (
            <NavMenu
              key={_menu}
              menu={_menu}
              isSelected={_menu === menu}
              onClick={this.onClickMenu}
            />
          );
        })}
      </div>
    );
  }
}
export default connect(state => ({
  user: state.user,
  semester: state.semester
}))(withRouter(injectIntl(Navbar)));

const NavMenu = injectIntl(function(props) {
  const { intl, menu, isSelected, onClick } = props;
  const title = intl.formatMessage({
    id: `ID_NAV_MENU_TITLE_${menu.toUpperCase()}`
  });
  const icon = icons[menu];
  return (
    <div
      className={`dashboard_navbar_menu${
        isSelected ? " dashboard_navbar_menu-selected" : ""
      }`}
      onClick={() => {
        onClick(menu);
      }}
    >
      {icon && (
        <img className="dashboard_navbar_menu_icon" src={icon} alt={menu} />
      )}
      <div className="dashboard_navbar_menu_title">{title}</div>
    </div>
  );
});
