import React, { Component } from "react";
import moment from "moment";
import * as request from "../../Common/Util/HTTPRequest";
import PopUp from "../../Common/PopUp";
import View from "./View";
import "./index.scss";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginId: "",
      name: "",
      gender: true,
      createdAt: "",
      type: false
    };
  }
  componentDidMount() {
    this.loadStudent(this.props.studentId);
  }

  loadStudent(id) {
    request
      .getStudent(this.props.user.id, id)
      .then(res => res.json())
      .then(json => {
        if (json.success) {
          const student = {
            loginId: json.data.student.user.loginId,
            password: "****",
            name: json.data.student.user.name,
            gender: json.data.student.user.gender,
            createdAt: moment(json.data.student.user.createdAt).format(
              "YYYY-MM-DD HH:MM"
            ),
            type: json.data.student.user.type
          };
          this.student = student;
          this.setState({ ...student });
        } else {
          throw json;
        }
      })
      .catch(err => {
        this.setState({ student: null });
        console.error(err);
        if(err.message === "Unauthorized"){
          PopUp.alert({title: "다시 로그인 해주세요."});
          const { navigate } = this.props;
          sessionStorage.removeItem("loginToken");
          navigate("/");
        }
      });
  }
  updateStudent(id, values) {
    request
      .updateStudent(this.props.user.id, id, values)
      .then(res => res.json())
      .then(json => {
        if (json.success && json.data) {
          this.student = { ...this.student, values };
          PopUp.alert({ title: "수정했습니다." });

          const { navigate } = this.props;
          navigate('/dashboard/master/students');
        } else {
          throw json;
        }
      })
      .catch(err => {
        console.error(err);
        if(err.message === "Unauthorized"){
          PopUp.alert({title: "다시 로그인 해주세요."});
          const { navigate } = this.props;
          sessionStorage.removeItem("loginToken");
          navigate("/");
        }
      });
  }

  onClickCancel = () => {
    const { navigate } = this.props;
    navigate(-1);
  }

  onChangeField = (value, id) => {
    this.setState({ [id]: value });
  };

  onClickEdit = () => {
    this.updateStudent(this.props.studentId, this.getUpdated());
  };
  getUpdated() {
    const updated = {};
    const {
      password,
      name,
      gender,
      type
    } = this.state;
    const values = { password, name, gender, type };
    for (let key in values) {
      if (values[key] !== this.student[key]) {
        if(key == "type") {
          updated["dbType"] = values[key] === 'true';
          continue;
        }
        else if(key == 'gender'){
          updated[key] = values[key] === 'true';
          continue;
        }
        updated[key] = values[key];
      }
    }
    return updated;
  }

  render() {
    return (
      <View
        {...this.state}
        onChangeField={this.onChangeField}
        onClickEdit={this.onClickEdit}
        onClickCancel={this.onClickCancel}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(Container);

// export default Container;
