import React from "react";
import { IntlProvider } from "react-intl";
import messages from "./messages";

const defaultLocale = "ko";
const locale = defaultLocale;

export function IntlWrapper(props) {
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {props.children}
    </IntlProvider>
  );
}
