import React, { Component } from "react";
import QueryString from "query-string";
import * as request from "../../Common/Util/HTTPRequest";
import Student from "../Student";
import View from "./View";
import "./index.scss";
import { withRouter } from "../../Common/Util/withRouter";
import PopUp from "../../Common/PopUp";

class Students extends Component {
  constructor(props) {
    super(props);
    this.state = { total: 0, students: [] };
  }
  componentDidMount() {
    this.loadStudents();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      this.loadStudents();
    }
  }

  loadStudents() {
    const { user } = this.props;
    request
      .getStudents(user.id, 50000)
      .then(res => res.json())
      .then(json => {
        if (json.success) {
          const { total, students } = json.data;
          this.setState({ total, students });
        }
        else {
          throw json;
        }
      })
      .catch(err => {
        this.setState({ total: 0, students: [] });
        console.error(err);
        if(err.message === "Unauthorized"){
          PopUp.alert({title: "다시 로그인 해주세요."});
          const { navigate } = this.props;
          sessionStorage.removeItem("loginToken");
          navigate("/");
        }
      });
  }

  onClickStudent = student => {
    const { navigate, location } = this.props;
    const { search, pathname } = location;
    const query = QueryString.parse(search);
    query.studentId = student.id;
    const queryString = QueryString.stringify(query);
    navigate(pathname + "?" + queryString);
  };

  render() {
    const { location } = this.props;
    const { studentId } = QueryString.parse(location.search);
    if (studentId) {
      return <Student {...this.props} studentId={studentId} />;
    } else {
      const { total, students } = this.state;
      return (
        <View
          total={total}
          students={students}
          onClickStudent={this.onClickStudent}
        />
      );
    }
  }
}

export default withRouter(Students);
