
const MAX_FILE_SIZE=256;
const ko = {
  ID_TITLE: "어드민",

  ID_MASTER: "마스터",
  ID_INSTITUTION: "기관",

  ID_CREATE: "생성",
  ID_NEW_ALARM: "새로운 알람",
  ID_NUMBER: "번호",
  ID_CREATED_DATE: "생성일",

  ID_NAV_MENU_TITLE_HOME: "개요",
  ID_NAV_MENU_TITLE_INSTRUCTORS: "코치 관리하기",
  ID_NAV_MENU_TITLE_INSTITUTIONS: "기관 관리하기",
  ID_NAV_MENU_TITLE_STUDENTS: "회원 관리하기",
  ID_NAV_MENU_TITLE_SMS: "SMS",
  ID_NAV_MENU_TITLE_NOTICES: "공지사항",
  ID_NAV_MENU_TITLE_SMS_LIST: "메세지 전송 내역",
  ID_NAV_MENU_TITLE_SMS_DETAIL: "메세지 전송 상세내역",
  ID_NAV_MENU_TITLE_SURVEYFORM: "만족도 양식",
  ID_NAV_MENU_TITLE_OPENCLASS: "클래스 개설",
  ID_NAV_MENU_TITLE_MANAGECLASS: "클래스 관리",
  ID_NAV_MENU_TITLE_MANAGECLASSGROUP: "클래스 운영",
  ID_NAV_MENU_TITLE_PAYMENTS: "결제 내역",
  ID_NAV_MENU_TITLE_GROUPSETTLEMENTS: "클래스별 정산",
  ID_NAV_MENU_TITLE_MONTHLYSETTLEMENTS: "클래스 월별 정산",
  ID_NAV_MENU_TITLE_ENTERPRISELIST: "기업",
  ID_NAV_MENU_TITLE_SCHOOLANDACADEMY: "센터 관리하기",
  ID_NAV_MENU_TITLE_MASTERHOME: "개요",
  ID_NAV_MENU_TITLE_EVENTS: "이벤트 관리",
  ID_NAV_MENU_TITLE_REVIEWS: "후기",
  ID_NAV_MENU_TITLE_REVIEWREPORTS: "후기 신고 내역",
  ID_NAV_MENU_TITLE_ADDUSER: "회원 추가",
  ID_NAV_MENU_TITLE_WEBMAINBANNER: "웹 매인배너 관리",
  ID_NAV_MENU_TITLE_WEBMIDBANNER: "웹 미드배너 관리",
  //ID_NAV_MENU_TITLE_WEBAVENGER: "웹 어벤저스 관리",
  ID_NAV_MENU_TITLE_NEWCENTER: "센터 회원가입",
  ID_NAV_MENU_TITLE_SWINGS: "스윙정보",
  ID_NAV_MENU_TITLE_PADLOGS: "패드 로그",

  ID_SEMESTER_MENU_TITLE_SEMESTERMANAGE: "기수 운영",
  ID_SEMESTER_MENU_TITLE_OPENCLASS: "클래스 개설",
  ID_SEMESTER_MENU_TITLE_MANAGEAPPLIES: "수강신청 관리",
  ID_SEMESTER_MENU_TITLE_MANAGECANCELS: "수강취소 관리",
  ID_SEMESTER_MENU_TITLE_TUTORS: "활동 중인 강사",
  ID_SEMESTER_MENU_TITLE_CLASSMANAGE: "클래스 운영",
  ID_SEMESTER_MENU_TITLE_CLASSGROUPS: "클래스 운영",
  ID_SEMESTER_MENU_TITLE_TUTORADJUSTMENTS: "강사 정산",
  ID_SEMESTER_MENU_TITLE_SURVEYS: "만족도 평가",

  ID_POPUP_DEFAULT_CONFIRM: "확인",
  ID_POPUP_DEFAULT_CANCEL: "취소",
  ID_POPUP_DEFAULT_ALERT_TITLE: "확인을 클릭해주세요",
  ID_POPUP_DEFAULT_CONFIRM_TITLE: "확인이나 취소를 클릭해주세요",
  ID_POPUP_DEFAULT_PROMPT_TITLE: "값을 입력하고 확인을 클릭해주세요",
  ID_POPUP_DEFAULT_PROMPT_PLACEHOLDER: "값을 입력해주세요",
  ID_POPUP_DEFAULT_FLASH_TITLE: "잠시 후 닫힙니다...",
  ID_POPUP_DEFAULT_CLASS_INFO_TITLE: "클래스 정보 보기",
  ID_POPUP_DEFAULT_CLASS_INFO_MONITORING_LINK: "클래스 모니터링 페이지 이동",

  ID_POPUP_REQUEST_ERROR:
    "요청 중 오류가 발생했습니다. 다음에 다시 시도해 주세요.",

  ID_POPUP_CONFIRM_OPENCLASS_SAVE: `모든 항목을 확인 후 신중히 저장해주세요.\n 저장하시겠습니까?`,
  ID_POPUP_CONFIRM_GENERALCLASS_SAVE:
    "저장 후 변경 불가합니다. 저장하시겠습니까?",

  ID_VALIDATE_FAIL_MAXSERIALKEYS_NULL: "갯수를 입력해주세요",

  ID_VALIDATE_FAIL_ADDRESS_NULL: "주소를 입력해주세요",

  ID_VALIDATE_FAIL_EMAIL_NULL: "이메일을 입력해주세요",
  ID_VALIDATE_FAIL_EMAIL_FORMAT: "이메일 형식을 바르게 입력해주세요",
  ID_VALIDATE_FAIL_LOGINID_NULL: "아이디를 입력해주세요",
  ID_VALIDATE_FAIL_LOGINID_FORMAT:
    "아이디는 영문 소문자,숫자만 사용할 수 있습니다",
  ID_VALIDATE_FAIL_LOGINID_LENGTH: "아이디를 5자 이상 입력해주세요",
  ID_VALIDATE_FAIL_PW_NULL: "비밀번호를 입력해주세요",
  ID_VALIDATE_FAIL_PW_LENGTH: "비밀번호를 6자 이상으로 입력해주세요",
  ID_VALIDATE_FAIL_PWRE_NULL: "비밀번호를 한 번 더 입력해주세요",
  ID_VALIDATE_FAIL_PWRE_DIFF: "비밀번호가 일치하지 않습니다",
  ID_VALIDATE_FAIL_PHONE_FORMAT: "휴대폰번호 형식이 올바르지 않습니다.",

  ID_VALIDATE_FAIL_USERNAME_NULL: "이름을 입력해주세요",
  ID_VALIDATE_FAIL_USERNAME_FORMAT: "이름을 올바르게 입력해주세요",

  ID_VALIDATE_FAIL_BUSINESSNUMBER_NULL: "사업자등록번호를 입력해주세요",
  ID_VALIDATE_FAIL_BUSINESSNUMBER_FORMAT: "사업자등록번호를 올바르게 입력해주세요",

  ID_VALIDATE_FAIL_ADDRESS_NULL: "사업자등록번호를 입력해주세요",

  ID_VALIDATE_FAIL_NAME_NULL: "기관 이름을 입력해주세요",

  ID_FILTER_START_AFTER_DATE_SELECTED: "시작일 선택",
  ID_FILTER_END_BEFORE_DATE_SELECTED: "종료일 선택",
  ID_SELECT_YEAR: "년도 선택",

  ID_SCHOOL_SEMESTER_NAME: "기수 이름",
  ID_SCHOOL_APPLICATION_PERIOD: "접수 기간",
  ID_SCHOOL_SEMESTER_PERIOD: "기수 기간",
  ID_SCHOOL_SEMESTER_INFO: "기수 정보",
  ID_SCHOOL_CLASS_SCHEDULE: "클래스 일정",
  ID_SCHOOL_SEMESTER_MANAGEMENT: "기수 관리",
  ID_SCHOOL_STUDENT_MANAGEMENT: "학생 관리",
  ID_SCHOOL_ID_TRANSMISSION: "ID 전송",
  ID_SCHOOL_ID_CREATE: "ID 생성",
  ID_SCHOOL_ID_FORMAT_DOWNLAOD: "양식 내려받기",
  ID_SCHOOL_IMPORT: "일괄등록",
  ID_SCHOOL_EXPORT: "내보내기",
  ID_SCHOOL_COMMISSION: "수용비",

  ID_APPLY_ADDED: "수강신청 추가되었습니다.",
  ID_APPLY_CONFLICT: "해당 학생은 같은 시간에 다른 수업을 수강하고 있습니다.",
  ID_APPLY_MAX_REACHED:
    "정원을 초과하여 수강생을 추가할 수 없습니다.\n정원을 변경한 후 다시 시도해주세요.",
  ID_APPLY_NO_LOGIN_ID: "없는 아이디입니다.\n아이디를 확인해주세요.",
  ID_APPLY_NOT_IN_SCHOOL: "소속학생이 아닙니다.\n아이디를 확인해주세요.",
  ID_APPLY_NO_CLASSGROUP:
    "반 정보를 불러올 수 없습니다.\n관리자에게 문의해주세요.",
  ID_APPLY_ALREADY: "이미 신청된 학생입니다.",

  ID_DELETE_MESSAGE: "삭제하시겠습니까?",
  ID_DELETE_SUCCESS: "삭제 되었습니다.",
  ID_REJECT_SUCCESS: "반려되었습니다.",

  ID_AGE_PRESCHOOL: "미취학",
  ID_AGE_8: "초1",
  ID_AGE_9: "초2",
  ID_AGE_10: "초3",
  ID_AGE_11: "초4",
  ID_AGE_12: "초5",
  ID_AGE_13: "초6",
  ID_AGE_14: "중1",
  ID_AGE_15: "중2",
  ID_AGE_16: "중3",
  ID_AGE_17: "고1",
  ID_AGE_18: "고2",
  ID_AGE_19: "고3",
  ID_AGE_20: "일반",

  ID_DAY_0: "일",
  ID_DAY_1: "월",
  ID_DAY_2: "화",
  ID_DAY_3: "수",
  ID_DAY_4: "목",
  ID_DAY_5: "금",
  ID_DAY_6: "토",
  ID_DAY_7: "일",

  ID_DAY_LONG_0: "일요일",
  ID_DAY_LONG_1: "월요일",
  ID_DAY_LONG_2: "화요일",
  ID_DAY_LONG_3: "수요일",
  ID_DAY_LONG_4: "목요일",
  ID_DAY_LONG_5: "금요일",
  ID_DAY_LONG_6: "토요일",
  ID_DAY_LONG_7: "일요일",

  ID_STATUS_ALL: "전체",
  ID_STATUS_READY: "대기",
  ID_STATUS_APPROVED: "승인",
  ID_STATUS_REJECTED: "반려",
  ID_STATUS_URGENT: "긴급",
  ID_STATUS_DELETE: "삭제",
  ID_STATUS_NO_DELETE: "삭제불가",
  ID_STATUS_NO_DELETE_APPLIED: "삭제불가",
  ID_STATUS_REJECT: "반려",
  ID_STATUS_PAID: "결제",
  ID_STATUS_CANCELED: "환불",

  ID_CLASSGROUP_STATUS_READY: "수업준비",
  ID_CLASSGROUP_STATUS_INPROCESS: "수업운영",
  ID_CLASSGROUP_STATUS_FINISHED: "수업완료",
  ID_CLASSGROUP_STATUS_CLOSED: "폐강",

  ID_APPLY_STATUS_READY: "대기",
  ID_APPLY_STATUS_APPROVED: "선정",
  ID_APPLY_STATUS_REJECTED: "탈락",
  ID_APPLY_STATUS_CANCELED: "취소",

  ID_APPLIED_STATUS_READY: "신청",
  ID_APPLIED_STATUS_APPROVED: "결제완료",
  ID_APPLIED_STATUS_CANCELED: "환불",
  ID_APPLIED_STATUS_CANCEL_READY: "취소신청",
  ID_APPLIED_STATUS_CANCEL_APPROVED: "환불",
  ID_MYGROUP_STATUS_CANCELAPPLIED: "취소신청",
  ID_MYGROUP_STATUS_CANCELAPPROVED: "환불",

  ID_LECTURE_STATUS_READY: "신청",
  ID_LECTURE_STATUS_IN_REVIEW: "검토중",
  ID_LECTURE_STATUS_REJECTED: "승인거절",
  ID_LECTURE_STATUS_APPROVED: "승인완료",
  ID_LECTURE_STATUS_IN_PROCESS: "진행 중",
  ID_LECTURE_STATUS_FINISHED: "완료",
  ID_LECTURE_STATUS_CLOSED: "폐강",
  ID_LECTURE_STATUS_CANCELED: "취소",
  ID_RECRUIT_IN_PROCESS: "모집중",
  ID_RECRUIT_FINISHED: "모집종료",

  ID_TUTOR_FIELD_TITLE_LOGINID: "아이디",
  ID_TUTOR_FIELD_TITLE_NAME: "이름",
  ID_TUTOR_FIELD_TITLE_PHONE: "휴대전화 번호",
  ID_TUTOR_FIELD_TITLE_EMAIL: "이메일",
  ID_TUTOR_FIELD_TITLE_BIRTH: "생년월일",
  ID_TUTOR_FIELD_TITLE_GENDER: "성별",
  ID_TUTOR_FIELD_TITLE_ACCOUNT: "정산 계좌",
  ID_TUTOR_FIELD_TITLE_IDENTIFICATION: "신원조회 동의서",

  ID_POPUP_LECTURE_MAIN_MEDIA_SIZE_TITLE: "파일 사이즈가 너무 큽니다.",
  ID_POPUP_LECTURE_MAIN_IMAGE_SIZE: `${MAX_FILE_SIZE.IMAGE_MB}MB 이하의 이미지를 등록해 주세요.`,
  ID_POPUP_LECTURE_MAIN_VIDEO_SIZE: `${MAX_FILE_SIZE.VIDEO_MB}MB 이하의 영상을 등록해 주세요.`,

  ID_SCHOOL_CLASSGROUPS_MENU_CLASSES: "수업 현황",
  ID_SCHOOL_CLASSGROUPS_MENU_STUDENTS: "출결 관리",
  ID_SCHOOL_CLASSGROUPS_MENU_QUIZZES: "퀴즈&과제",
  ID_SCHOOL_CLASSGROUPS_MENU_EARNINGS: "정산",

  ID_QUIZ_LIST: "퀴즈 목록",
  ID_HOMEWORK_LIST: "과제 목록",

  ID_ALL: "전체",
  ID_EVALUATION_DONE: "평가 완료",
  ID_EVALUATION_UNDONE: "평가 전",
  ID_RESULT: "결과",
  ID_CHECK_RESULT: "결과 확인",
  ID_ALL_PARTICIPANTS: "총 참여",
  ID_PARTICIPANTS: "참여자",
  ID_ALL_COUNT: "총 {count}명",
  ID_STATUS: "상태",
  ID_SCORE: "점수",
  ID_EVALUATION: "평가",
  ID_START_DATE: "시작일",
  ID_END_DATE: "종료일",
  ID_CLASSROOM_QUIZ: "퀴즈",
  ID_CLASSROOM_QUIZ_OPTION: "보기",
  ID_CLASSROOM_QUIZ_QUESTION: "질문",
  ID_CLASSROOM_QUIZ_ANSWER: "정답",
  ID_CLASSROOM_QUIZ_WRONG_ANSWER: "오답",
  ID_CLASSROOM_QUIZ_SOLUTION: "해설",
  ID_CLASSROOM_QUIZ_DETAIL: "상세 내용",
  ID_CLASSROOM_QUIZ_CHECK_RESULT: "퀴즈 결과 확인",
  ID_CLASSROOM_QUIZ_ANSWER_COUNT: "응답 수",
  ID_CLASSROOM_ATTACH_IMAGE: "이미지 첨부",
  ID_CLASSROOM_FIND_FILE: "파일 찾기",
  ID_CLASSROOM_HOMEWORK_DESCRIPTION: "과제 설명",
  ID_CLASSROOM_HOMEWORK_EVALUATION: "과제 평가",
  ID_CLASSROOM_HOMEWORK_RESULT: "과제 결과 확인",
  ID_CLASSROOM_HOMEWORK_NO_SCORE: "점수 없음",
  ID_CLASSROOM_STUDENT_NAME: "학생명",
  ID_CLASSROOM_CHECK_HOMEWORK: "과제 보기",
  ID_CLASSROOM_SUBMIT_DATE: "제출일",
  ID_PEOPLE_COUNT: "명",

  ID_SAVE_SHORT: "저장",

  ID_NOTICE_WRITE: "공지사항 작성",
  ID_NOTICE_ADD: "새로 작성",
  ID_TITLE: "제목",
  ID_CONTENT: "내용",
  ID_WRITED_DATE: "일자",
  ID_ATTACH_FILE: "첨부파일",
  ID_ADD_FILE: "파일 추가",
  ID_SAVE: "저장하기",
  ID_SAVED: "저장되었습니다.",
  ID_EDIT: "수정",
  ID_DELETE: "삭제",
  ID_LIST: "목록",
  ID_CONFIRM_DELETE: "정말 삭제하시겠습니까?",
  ID_DELETED: "삭제되었습니다.",
  ID_NOTICE_EMPTY: "등록된 공지사항이 없습니다.",
  ID_MYCLASS_NEXT_TEXT: "다음글",
  ID_MYCLASS_PREV_TEXT: "이전글",

  ID_ATTENDANCE_STATUS_ATTENDANCE: "출석",
  ID_ATTENDANCE_STATUS_LATE: "지각",
  ID_ATTENDANCE_STATUS_ABSENT: "결석",
  ID_ATTENDANCE_STATUS_NOT_PROGRESSED: "미수업",

  ID_NOTICE_MAX_COUNT_ALERT: "최대 파일의 갯수는3개 입니다.",
  ID_STUDENT_BUNDLE_WARNING:
    "[{row}] 번째 행에 [ {columnName} ] 값이 비어있거나 형식이 잘못되었습니다."
};

export default ko;
