import React, { Component } from "react";
import moment from "moment";
import * as request from "../../Common/Util/HTTPRequest";
import PopUp from "../../Common/PopUp";
import View from "./View";
import "./index.scss";
import { connect } from "react-redux";
import Validate from "../../Common/Util/Validate";
import { injectIntl } from "react-intl";

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginId: "",
      name: "",
      createdAt: "",
      warnings: []
    };
  }
  componentDidMount() {
    this.loadInstitution(this.props.institutionId);
  }

  loadInstitution(id) {
    request
      .getInstitution(this.props.user.id, id)
      .then(res => res.json())
      .then(json => {
        if (json.success) {
          const institution = {
            loginId: json.data.institution.admin.loginId,
            password: "****",
            ceoName: json.data.institution.ceoName,
            name: json.data.institution.name,
            address: json.data.institution.address,
            businessNumber: json.data.institution.businessNumber,
            isApproved: String(json.data.institution.isApproved),
            phoneNumber: json.data.institution.phoneNumber,
            email: json.data.institution.email,
            maxSerialKeys: json.data.institution.maxSerialKeys,
            createdAt: moment(json.data.institution.createdAt).format(
              "YYYY-MM-DD HH:MM"
            )
          };
          this.institution = institution;
          this.setState({ ...institution });
        } else {
          throw json;
        }
      })
      .catch(err => {
        this.setState({ institution: null });
        console.error(err);
        if(err.message === "Unauthorized"){
          PopUp.alert({title: "다시 로그인 해주세요."});
          const { navigate } = this.props;
          sessionStorage.removeItem("loginToken");
          navigate("/");
        }
      });
  }
  updateInstitution(id, values) {
    request
      .updateInstitution(this.props.user.id, id, values)
      .then(res => res.json())
      .then(json => {
        if (json.success && json.data) {
          this.institution = { ...this.institution, values };
          PopUp.alert({ title: "수정했습니다." });

          const { navigate } = this.props;
          navigate('/dashboard/master/institutions');
        } else {
          throw json;
        }
      })
      .catch(err => {
        console.error(err);
        if(err.message === "Unauthorized"){
          PopUp.alert({title: "다시 로그인 해주세요."});
          const { navigate } = this.props;
          sessionStorage.removeItem("loginToken");
          navigate("/");
        }
      });
  }

  onClickCancel = () => {
    const { navigate } = this.props;
    navigate(-1);
  }

  onChangeField = (value, id) => {
    this.setState({ [id]: value });
  };

  onChangeIsApproved = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }

  onClickEdit = () => {
    if(this.validateAllFields())
      this.updateInstitution(this.props.institutionId, this.getUpdated());
  };

  validateAllFields() {
    let result = true;
    const {
      password,
      ceoName,
      name,
      address,
      businessNumber,
      phoneNumber,
      email,
      maxSerialKeys,
      isApproved
    } = this.state;
    const values = { password, ceoName, name, address, businessNumber, phoneNumber, email, maxSerialKeys, isApproved };
    const warnings = { ...this.state.warnings };
    for (let key in values) {
      if (values[key] !== this.institution[key]) {
        if(["password", "name", "email", "phoneNumber", "address", "maxSerialKeys", "ceoName", "businessNumber"].includes(key)){
          const { result: _result, reason } = Validate[key === "ceoName"? "username" : key](values[key]);
          if (!_result) result = false;
          const warning = 
            !_result &&
            this.props.intl.formatMessage({
              id: "ID_VALIDATE_FAIL_" + reason.toUpperCase()
            });
          warnings[key] = warning;
        }
      }
    }
    this.setState({ warnings });
    return result;
  }

  getUpdated() {
    const updated = {};
    const {
      password,
      ceoName,
      name,
      address,
      businessNumber,
      phoneNumber,
      email,
      maxSerialKeys,
      isApproved
    } = this.state;
    const values = { password, ceoName, name, address, businessNumber, phoneNumber, email, maxSerialKeys, isApproved };
    for (let key in values) {
      if (values[key] !== this.institution[key]) {
        updated[key] = values[key];
        if(key === "isApproved"){
          updated[key] = values[key] === "true"? true: false;
        }
      }
    }
    return updated;
  }

  render() {
    return (
      <View
        {...this.state}
        onChangeField={this.onChangeField}
        onClickEdit={this.onClickEdit}
        onClickCancel={this.onClickCancel}
        onChangeIsApproved={this.onChangeIsApproved}
        warnings={this.state.warnings}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(injectIntl(Container));

// export default Container;
