import React from "react";
import moment from "moment";
import DataTable from "../../Common/Component/DataTable";
import "./index.scss";

function View(props) {
  const { total } = props;
  return (
    <div className="instructors">
      <div className="dashboard_content_title">
        코치 관리하기 <span>({total}명)</span>
      </div>
      <Table {...props} />
    </div>
  );
}
export default View;

function Table(props) {
  const { instructors, onClickInstructor, onClickInstructorLesson } = props;

  let count = 0;
  const data = instructors.map(instructor => (
    count = count + 1,
    {
    id: instructor.id,
    loginId: instructor.user.loginId,
    name: instructor.user.name,
    gender: instructor.user.gender,
    certificate: instructor.certificate,
    introduce: instructor.introduce,
    createdAt: moment(instructor.user.createdAt).format("YYYY-MM-DD"),
    lessons: instructor.lessons,
    count: count
  }));

  const columns = [
    { name: "count", label: "번호" },
    {
      name: "loginId",
      label: "계정 아이디",
      options: {
        customBodyRenderLite: i => (
          <span
            className="loginId"
            onClick={() => {
              onClickInstructor(instructors[i]);
            }}
          >
            {instructors[i].user.loginId}
          </span>
        )
      }
    },
    { name: "name", label: "이름" },
    { 
      name: "gender", 
      label: "성별",
      options: {
        customBodyRenderLite: i => {
          if(instructors[i].user.gender === true){
            return <span>남자</span>
          }
          else{
            return <span>여자</span>
          }
        }
      }
    },
    { 
      name: "certificate", 
      label: "자격증" ,
      options: {
        customBodyRender: (value) => {
          return value || "-";
        }
      }},
    { 
      name: "introduce", 
      label: "소개",
      options: {
        customBodyRender: (value) => {
          return value || "-";
        }
      } 
    },
    { name: "createdAt", label: "가입일" },
    {
      name: "lessons",
      label: "레슨",
      options: {
        customBodyRenderLite: i => (
          <span
            className="lessons"
            onClick={() => {
              onClickInstructorLesson(instructors[i]);
            }}
          >
            회원정보
          </span>
        )
      }
    },
  ];
  const options = {
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    textLabels: { body: { noMatch: "-" } }
  };

  return (
    <div className="instructors_table">
      <DataTable data={data} columns={columns} options={options} />
    </div>
  );
}
