import ACTION from "../ActionType";

export const updateUser = user => ({
  type: ACTION.UPDATE_USER,
  payload: { user }
});

export const clearUser = () => ({
  type: ACTION.CLEAR_USER
});

export const getUser = () => ({
  type: ACTION.GET_USER
});

const initialState = {};

const userInfo = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.UPDATE_USER:
      return { ...state, ...payload.user };
    case ACTION.CLEAR_USER:
      return { ...initialState };
    case ACTION.GET_USER:
      return { ...state, user:state.user }
    default:
      return state;
  }
};

export default userInfo;
