import React from "react";
import "./index.scss";

import logoImg from "../../Image/tempodiall.png";

export default function (props) {
  const { loginId, password, warning, onChangeInput, onClickLogin } = props;
  return (
    <div className="login">
      <div className="login_centered">
        <div className="login_logo">
          <img src={logoImg} alt="logo" />
          <div className="text-overlay">관리자</div>
        </div>
        <div className="login_form">
          <div className="login_field login_field-loginId">
            <div className="login_field_title">아이디</div>
            <input
              id="loginId"
              value={loginId}
              placeholder="아이디 입력"
              onChange={onChangeInput}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  document.getElementById("password")?.focus();
                }
              }}
            />
          </div>
          <div className="login_field login_field-password">
            <div className="login_field_title">비밀번호</div>
            <input
              id="password"
              type="password"
              value={password}
              placeholder="비밀번호 입력"
              onChange={onChangeInput}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  onClickLogin();
                }
              }}
            />
          </div>
        </div>
        <button className="login_button" onClick={onClickLogin}>
          로그인
        </button>
        {warning && <div className="login_warning">{warning}</div>}
      </div>
    </div>
  );
}
