function email(email, compulsory = true) {
  if (compulsory) {
    // 필수
    if (!email || email === "") {
      return { result: false, reason: "email_null" };
    }
    const regex = /^[a-z0-9_+.-]+@([a-z0-9-]+\.)+[a-z0-9]{2,4}$/.test(email);
    return { result: regex, reason: "email_format" };
  } else {
    // 선택
    if (email || email.length > 0) {
      const regex = /^[a-z0-9_+.-]+@([a-z0-9-]+\.)+[a-z0-9]{2,4}$/.test(email);
      return { result: regex, reason: "email_null" };
    }
    return { result: true };
  }
}

function loginId(loginId) {
  if (!loginId || loginId === "") {
    return { result: false, reason: "loginId_null" };
  }

  if (!/^[0-9a-z]+$/.test(loginId)) {
    return { result: false, reason: "loginId_format" };
  }

  if (loginId.length < 5) {
    return { result: false, reason: "loginId_length" };
  }

  return { result: true };
}

function password(password) {
  if (!password || password === "") {
    return { result: false, reason: "pw_null" };
  }

  // if (password.length < 6) {
  //   return { result: false, reason: "pw_length" };
  // }

  //   if (!/^[0-9a-zA-Z~`!@#$%\\^&*()-+=]{10,}$/.test(password)) {
  //     return { result: false, id: "ID_VALIDATE_FAIL_PW_LENGTH" };
  //   }

  //   const isNumExist = password.search(/[0-9]/g) >= 0 ? 1 : 0;
  //   const isEngExist = password.search(/[a-z]/gi) >= 0 ? 1 : 0;
  //   const isSpecExist = password.search(/[~`!@#$%\\^&*()-+=]/gi) >= 0 ? 1 : 0;
  //   if (isNumExist + isEngExist + isSpecExist < 2) {
  //     return { result: false, id: "ID_VALIDATE_FAIL_PW_COMB" };
  //   }

  //   if (/([0-9])\1\1+/.test(password)) {
  //     return { result: false, id: "ID_VALIDATE_FAIL_PW_SEQUENCE" };
  //   }

  return { result: true };
}

function passwordRe(password, password_re) {
  if (!password_re || password_re === "") {
    return { result: false, reason: "pwre_null" };
  }

  if (password !== password_re) {
    return { result: false, reason: "pwre_diff" };
  }

  return { result: true };
}

function minMaxString(min, max, string) {
  const strLength = string.replaceAll(" ", "").trim().length;

  if (!(+min < strLength && strLength < +max)) {
    return { result: false, reason: "min_max_invalid" };
  }
  return { result: true };
}

function phoneNumber(phone) {
  if (!phone || phone === "") {
    return { result: false, reason: "phone_format" };
  }

  const regEx = /^[0-9]{3}[0-9]{3,4}[0-9]{4}$/;

  if (!regEx.test(phone)) {
    return { result: false, reason: "phone_format" };
  }

  return { result: true };
}

function number(number, compulsory = true) {
  if (compulsory) {
    if (!number || number === "") {
      return { result: false, reason: "" };
    }
    const regex = /^[0-9]*$/.test(number);
    return { result: regex, reason: "" };
  } else {
    if (number || number.length > 0) {
      const regex = /^[0-9]*$/.test(number);
      return { result: regex, reason: "" };
    }
    return { result: true };
  }
}

function username(username) {
  if (!username || username === "") {
    return { result: false, reason: "USERNAME_NULL" };
  }

  const isKorName = /^[가-힣\s]+$/.test(username);
  const isEngName = /^[a-zA-Z\s]+$/.test(username);
  if (!(isKorName || isEngName)) {
    return { result: false, reason: "USERNAME_FORMAT" };
  }

  return { result: true };
}

function birth(birth, compulsory = true) {
  const dateFormat = dateString => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) return { result: false, reason: "" }; // Invalid format
    const d = new Date(dateString);
    const dNum = d.getTime();
    const dYear = d.getFullYear();
    const currentYear = new Date().getFullYear();
    if ((!dNum && dNum !== 0) || dYear > currentYear || dYear < 1900)
      return { result: false, reason: "" }; // NaN value, Invalid date

    const result = d.toISOString().slice(0, 10) === dateString;
    return { result: result, reason: "" };
  };

  if (compulsory) {
    if (!birth || birth === "") {
      return { result: false, reason: "" };
    }
    return dateFormat(birth);
  } else {
    if (birth || birth.length > 0) {
      return dateFormat(birth);
    }
    return { result: true };
  }
}

function address(address, compulsory = true){
  if(compulsory){
    if(!address || address === ""){
      return { result: false, reason: "address_null"}
    }
  }
  return { result: true };
}

function maxSerialKeys(maxSerialKeys, compulsory = true){
  if(compulsory){
    if(!maxSerialKeys || maxSerialKeys === ""){
      return { result: false, reason: "maxSerialKeys_null"}
    }
  }
  return { result: true };
}

function businessNumber(number) {
  if (!number || number === "") {
    return { result: false, reason: "businessNumber_null" };
  }

  // 사업자등록번호 포맷 검증을 위한 정규 표현식: XXX-XX-XXXXX
  const regEx = /^[0-9]{3}[0-9]{2}[0-9]{5}$/;

  if (!regEx.test(number)) {
    return { result: false, reason: "businessNumber_format" };
  }

  // 체크섬(검증코드)을 계산하는 로직

  return { result: true };
}

function name(name) {
  if (!name || name === "") {
    return { result: false, reason: "NAME_NULL" };
  }

  return { result: true };
}

const Validate = {
  email,
  loginId,
  password,
  passwordRe,
  minMaxString,
  phoneNumber,
  number,
  username,
  birth,
  address,
  maxSerialKeys,
  businessNumber,
  name
};
export default Validate;
