import React, { Component } from "react";
import moment from "moment";
import * as request from "../../Common/Util/HTTPRequest";
import PopUp from "../../Common/PopUp";
import View from "./View";
import "./index.scss";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginId: "",
      name: "",
      gender: true,
      createdAt: "",
      isApprove: "",
      requestedBy: ""
    };
  }
  componentDidMount() {
    this.loadLesson(this.props.lessonId, this.props.instructorId);
  }

  loadLesson(lessonId, instructorId) {
    const { user } = this.props;
    request
      .getInstructorLesson(this.props.user.id, instructorId, lessonId, user.type === "institution"? user.institution.id: null)
      .then(res => res.json())
      .then(json => {
        if (json.success) {
          const student = {
            loginId: json.data.lesson.student.user.loginId,
            name: json.data.lesson.student.user.name,
            gender: json.data.lesson.student.user.gender,
            createdAt: moment(json.data.lesson).format(
              "YYYY-MM-DD HH:MM"
            ),
            isApprove: json.data.lesson.isApprove,
            requestedBy: json.data.lesson.requestedBy
          };
          this.student = student;
          this.setState({ ...student });
        } else {
          throw json;
        }
      })
      .catch(err => {
        this.setState({ student: null });
        console.error(err);
        if(err.message === "Unauthorized"){
          PopUp.alert({title: "다시 로그인 해주세요."});
          const { navigate } = this.props;
          sessionStorage.removeItem("loginToken");
          navigate("/");
        }
      });
  }
  updateLesson(id, values) {
    request
      .updateLesson(this.props.user.id, id, values)
      .then(res => res.json())
      .then(json => {
        if (json.success && json.data) {
          // this.student = { ...this.student, values };
          PopUp.alert({ title: "수정했습니다." });

          const { navigate } = this.props;
          navigate(-1);
        } else {
          throw json;
        }
      })
      .catch(err => {
        console.error(err);
        if(err.message === "Unauthorized"){
          PopUp.alert({title: "다시 로그인 해주세요."});
          const { navigate } = this.props;
          sessionStorage.removeItem("loginToken");
          navigate("/");
        }
      });
  }

  onClickCancel = () => {
    const { navigate } = this.props;
    navigate(-1);
  }

  onChangeField = (value, id) => {
    this.setState({ [id]: value });
  };

  onClickEdit = () => {
    const params = this.getUpdated();
    if(Object.keys(params).length === 0){
      const { navigate } = this.props;
      navigate(-1);
    }
    else{
      this.updateLesson(this.props.lessonId, this.getUpdated());
    }
  };
  getUpdated() {
    const updated = {};
    const {
      isApprove
    } = this.state;
    const values = { isApprove };
    for (let key in values) {
      if (values[key] !== this.student[key]) {
        if(values[key] == "승인"){
          updated[key] = true;
        }
        else if(values[key] == "거절"){
          updated[key] = false;
        }
        else if(values[key] == "대기"){
          updated[key] = null;
        }
      }
    }
    return updated;
  }
  

  render() {
    return (
      <View
        {...this.state}
        onChangeField={this.onChangeField}
        onClickEdit={this.onClickEdit}
        onClickCancel={this.onClickCancel}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(Container);

// export default Container;
