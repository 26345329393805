import React from "react";
import Field from "../../Common/Component/Field";
import "./index.scss";

function View(props) {
  const {
    loginId,
    password,
    name,
    gender,
    type,
    createdAt,
    onChangeField,
    onClickEdit,
    onClickCancel
  } = props;

  const genderOptions = [
    { value: true, label: '남자' },
    { value: false, label: '여자' },
  ];
  const typeOptions = [
    { value: true, label: '프로' },
    { value: false, label: '일반' },
  ];

  return (
    <div className="student">
      <div className="dashboard_content_title">학생 상세정보</div>
      <div className="student_fields">
        <Field.Input id="loginId" title="아이디" value={loginId} fixed />
        <Field.Input
          id="password"
          title="비밀번호"
          value={password}
          onChange={onChangeField}
        />
        <Field.Input
          id="name"
          title="이름"
          value={name}
          onChange={onChangeField}
        />
        <Field.Select
          id="gender"
          title="성별"
          value={gender}
          options={genderOptions}
          onChange={onChangeField}
        />
        <Field.Select
          id="type"
          title="구분"
          value={type}
          options={typeOptions}
          onChange={onChangeField}
        />
        <Field.Input id="createdAt" title="가입일시" value={createdAt} fixed />
        <button className='btn_edit' onClick={onClickEdit}>수정하기</button>
        <button className='btn_cancel' onClick={onClickCancel}>돌아가기</button>
      </div>
    </div>
  );
}
export default View;
