import React from "react";
import MUIDataTable from "mui-datatables";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";
import moment from "moment";

function DataTable(props) {
  const onDownloadXlsx = (buildHead, buildBody, columns, values) => {
    if (!values) return false;
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=EUC-KR";
    const fileExtension = ".xlsx";
    const json = values.reduce((result, val, rowIdx) => {
      const temp = {};
      val.data.forEach((v, columnIdx) => {
        const {
          label,
          name,
          customBodyRenderLite,
          customBodyRender,
          display,
          excludeOnExcel
        } = columns[columnIdx];
        if (!excludeOnExcel && display !== "false") {
          const headerLabel = label || name;

          let displayValue = v;
          if (customBodyRenderLite) {
            displayValue = customBodyRenderLite(rowIdx);
          } else if (customBodyRender) {
            displayValue = customBodyRender(v, {
              rowIndex: rowIdx,
              columnIndex: columnIdx,
              rowData: val.data
            });
          }

          // displayValue가 selectBox인 경우 react element가 return되고 있음
          if (displayValue?.props?.name === "selectBox") {
            const value = displayValue.props?.value;
            const currOption = displayValue.props?.options?.find(
              option => option.value === value
            );
            displayValue = currOption?.label || value;
            temp[headerLabel] = displayValue;
          }

          if (displayValue?.type !== "button") {
            const elemType = displayValue?.type;
            if (elemType) {
              if (elemType === "input") {
                displayValue = displayValue.props?.value;
                // } else if (elemType.name === "SelectBox") {
                //   const value = displayValue.props?.value;
                //   const currOption = displayValue.props?.options?.find(
                //     option => option.value === value
                //   );
                //   displayValue = currOption?.label || value;
              } else {
                displayValue = displayValue.props?.children;
              }
            }
            // 정원 columnIdx : 6, 신청인원 columnIdx : 7
            // columnIdx는 후에 바뀔수도 있는 값이라 라벨로 구분.
            if (label === "정원" || label === "신청인원") {
              const findIndex = displayValue.indexOf("명");
              if (findIndex !== -1 && findIndex === displayValue.length - 1)
                displayValue = displayValue.slice(0, -1);
            }
            temp[headerLabel] = displayValue;
          }
        }
      });
      result.push(temp);
      return result;
    }, []);

    const fileName = `${props.downloadFilename ||
      "방클어드민"}_${moment().format("YYYYMMDD_HHmmss")}`;
    const ws = utils.json_to_sheet(json);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName + fileExtension);
    return false;
  };

  const onDownloadCsv = (buildHead, buildBody, columns, data) => {
    // TODO euc kr 인코딩
    const result = "\uFEFF" + buildHead(columns) + buildBody(data);
    return result;
  };

  const defaultOptions = {
    selectableRowsHideCheckboxes: true,
    onDownload: props.downloadType === "csv" ? onDownloadCsv : onDownloadXlsx
  };
  const _options = { ...defaultOptions, ...props.options };

  return <MUIDataTable {...props} options={_options} />;
}

export default DataTable;
