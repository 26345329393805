import React, { Component } from "react";
import * as request from "../../Common/Util/HTTPRequest";
import View from "./View";
import "./index.scss";
import { withRouter } from "../../Common/Util/withRouter";
import PopUp from "../../Common/PopUp";

class MasterHome extends Component {
  constructor(props) {
    super(props);
    this.state = { total: 0, swings: [] };
  }
  componentDidMount() {
    this.loadSwings();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      this.loadSwings();
    }
  }

  loadSwings() {
    const { user } = this.props;
    request
      .getSwings(user.id,)
      .then(res => res.json())
      .then(json => {
        if (json.success) {
          const { total, swings } = json.data;
          this.setState({ total, swings });
        }
        else {
          throw json;
        }
      })
      .catch(err => {
        this.setState({ total: 0, swings: [] });
        console.error(err);
        if(err.message === "Unauthorized"){
          PopUp.alert({title: "다시 로그인 해주세요."});
          const { navigate } = this.props;
          sessionStorage.removeItem("loginToken");
          navigate("/");
        }
      });
  }

  render() {
    const { total, swings } = this.state;
    return (
      <View
        total={total}
        swings={swings}
      />
    );
  }
}

export default withRouter(MasterHome);
