import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as userActions from "../../Store/Reducer/user";
import { withLoginInfo } from "../../Util/LoginManager";
import PopUp from "../../PopUp";
import "./index.scss";

import logoImg from "../../../Image/tempodiall.png";
import { withRouter } from "../../Util/withRouter";

class Header extends Component {
  onClickLogo = () => {
    const { navigate, params } = this.props;
    const { mode } = params;
    navigate(`/dashboard/${mode}/home`);
  };
  onClickMaster = () => {
    PopUp.flash({
      title: "마스터 페이지로 전환합니다",
      onTimeout: () => {
        this.props.navigate(`/dashboard/master/MasterHome`);
      }
    });
  };
  onClickUserPassword = () => {
    const { navigate, params } = this.props;
    navigate(`/dashboard/${params.mode}/user`);
  };
  onClickUserLogout = () => {
    PopUp.confirm({
      title: "로그아웃 할까요?",
      message: "저장되지 않은 내용은 사라집니다.",
      confirmButtonName: "로그아웃",
      onConfirm: this.props.logout
    });
  };

  render() {
    const {
      intl,
      user,
      enterprise,
      school,
      isMasterUser,
      isEnterpriseUser
    } = this.props;
    const { mode, menu } = this.props.params;

    const title = "";
    const isLogoDisabled = menu === "home";
    const logoText = intl.formatMessage({ id: "ID_" + mode.toUpperCase() });
    const levelText =
      intl.formatMessage({ id: "ID_" + user.type.toUpperCase() }) + " 관리자";

    return (
      <div className={`dashboard_header dashboard_header-${mode}`}>
        <div
          className={`dashboard_header_logo${
            isLogoDisabled ? " dashboard_header_logo-disable" : ""
          }`}
          onClick={this.onClickLogo}
        >
          <img src={logoImg} alt="logo" />
          {/* <span>{logoText}</span> */}
        </div>
        <div className="dashboard_header_title">{title}</div>
        <div className="dashboard_header_user">
          <div className="dashboard_header_user_level">{levelText}</div>
          <div className="dashboard_header_user_loginId">{user?.loginId}</div>
          <div
            className="dashboard_header_user_menu dashboard_header_user_menu-password"
            onClick={this.onClickUserPassword}
          >
            비밀번호 변경
          </div>
          <div
            className="dashboard_header_user_menu dashboard_header_user_menu-logout"
            onClick={this.onClickUserLogout}
          >
            로그아웃
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(
  withRouter(
    withLoginInfo(
      connect(state => ({ user: state.user }), {
        updateUser: userActions.updateUser
      })(Header)
    )
  )
);
