import React from "react";
import { injectIntl } from "react-intl";
import PopUp from "../";
import "./index.scss";

const Alert = injectIntl(function (props) {
  const { formatMessage } = props.intl;
  const {
    title = formatMessage({ id: "ID_POPUP_DEFAULT_ALERT_TITLE" }),
    message,
    buttonName = formatMessage({ id: "ID_POPUP_DEFAULT_CONFIRM" }),
    onClickButton
  } = props;
  return (
    <div className="popup_alert">
      <div className="popup_title">{title}</div>
      {message && <div className="popup_message">{message}</div>}
      <div className="popup_buttons">
        <div
          className="popup_button popup_button-confirm"
          onClick={onClickButton}
        >
          {buttonName}
        </div>
      </div>
    </div>
  );
});

export function alert(config = {}, options) {
  const { title, message, buttonName, onClickButton } = config;
  PopUp.show(
    <Alert
      title={title}
      message={message}
      buttonName={buttonName}
      onClickButton={() => {
        if (onClickButton) onClickButton();
        PopUp.dismiss();
      }}
    />,
    { ...options, dismissOverlay: false }
  );
}
