import Students from "../Students"
import Instructors from "../Instructors";
import AddUser from "../AddUser"
import Swings from "../Swings";
import MasterHome from "../MasterHome"

import homeIcon from "../../Image/nav_Icon-home.svg";
import noticeIcon from "../../Image/nav_Icon-notice.svg";
import semesterIcon from "../../Image/nav_Icon-semester.svg";
import smsIcon from "../../Image/nav_Icon-sms.svg";
import studentIcon from "../../Image/nav_Icon-student.svg";
import tutorIcon from "../../Image/nav_Icon-tutor.svg";
import schoolIcon from "../../Image/nav_Icon-school.svg";
import InstitutionHome from "../InstitutionHome";
import Institutions from "../Institutions";
import Instructor from "../Instructor";
import PadLogs from "../PadLogs";

export const menusForMode = {
  master: [
    "home",
    "students",
    "instructors",
    "institutions",
    "addUser",
    "swings"
    // "SchoolAndAcademy",
    // "EnterpriseList",
    // "openClass",
    // "manageClassGroup",
    // "payments",
    // "groupSettlements",
    // "monthlySettlements",
    // "reviews",
    // "reviewReports",
    // "events",
    // "webMainBanner",
    // "webMidBanner",
    // //"webAvenger",
    // "newCenter"
  ],
  institution: ["home", "instructors", "padLogs"]
};

export const semesterMenus = {
  semesterManage: ["openClass", "manageApplies", "manageCancels", "tutors"],
  classManage: ["classGroups", "tutorAdjustments", "surveys"]
};

export const contentsForMode = {
  master: {
    home: MasterHome,
    students: Students,
    instructors: Instructors,
    addUser: AddUser,
    institutions: Institutions,
    swings: Swings
    // SchoolAndAcademy: SchoolAndAcademy,
    // EnterpriseList: EnterpriseList,
    // openClass: SchoolOpenClass,
    // manageClassGroup: RunningLectures,
    // payments: Payments,
    // groupSettlements: GroupSettlements,
    // monthlySettlements: GroupSettlements,
    // user: User,
    // reviews: Reviews,
    // reviewReports: ReviewReports,
    // events: Events,
    // webMainBanner: WebMainBanner,
    // webMidBanner: WebMidBanner,
    // //webAvenger: WebAvenger,
    // newCenter: NewCenter,
  },
  institution: {
    home: InstitutionHome,
    instructors: Instructors,
    padLogs: PadLogs
  }
};

export const icons = {
  home: homeIcon,
  instructors: tutorIcon,
  addUser: tutorIcon,
  students: studentIcon,
  institutions: schoolIcon,
  MasterHome: homeIcon
};
