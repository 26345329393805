import React, { Fragment } from "react";
import moment from "moment";
import DataTable from "../../Common/Component/DataTable";
import "./index.scss";

function View(props) {
  const { total } = props;

  return (
    <div className="lessons">
      <div className="dashboard_content_title">
        레슨 학생 <span>({total}명)</span>
      </div>
      <Table {...props} />
    </div>
  );
}
export default View;

function Table(props) {
  const { lessons, onClickEdit } = props;

  const data = lessons.map(lesson => ({
    id: lesson.id,
    loginId: lesson.student.user.loginId,
    name: lesson.student.user.name,
    gender: lesson.student.user.gender,
    isApprove: lesson.isApprove,
    requestedBy: lesson.requestedBy,
    createdAt: moment(lesson.createdAt).format("YYYY-MM-DD")
  }));

  const columns = [
    {
      name: "cnt",
      label: "번호",
      options: {
        customBodyRenderLite: i => 
          (
          <span>{i + 1}</span>
        )
      }
    },
    {
      name: "loginId",
      label: "계정 아이디",
      options: {
        customBodyRenderLite: i => (
          <span className="loginId">
            {lessons[i].student.user.loginId}
          </span>
        )
      }
    },
    { name: "name", label: "이름" },
    { 
      name: "gender", 
      label: "성별",
      options: {
        customBodyRenderLite: i => {
          if(data[i].gender == true){
            return <span>남자</span>
          }
          else{
            return <span>여자</span>
          }
        }
      }
    },
    { 
      name: "isApprove", 
      label: "상태",
      options: {
        customBodyRenderLite: i => {
          if(lessons[i].isApprove == true){
            return(
              <span 
                className="isApprove"
                onClick={() => {
                  onClickEdit(lessons[i]);
                }}
              >
                승인
              </span>
            )
          }
          else if(lessons[i].isApprove == false){
            return(
              <span 
                className="isApprove"
                onClick={() => {
                  onClickEdit(lessons[i]);
                }}
              >
                거절
              </span>
            )
          }
          else{
            return(
              <span 
                className="isApprove"
                onClick={() => {
                  onClickEdit(lessons[i]);
                }}
              >
                대기
              </span>
            )
          }
        }
      }
    },
    { 
      name: "requestedBy", 
      label: "신청자",
      options: {
        customBodyRenderLite: i => {
          if(lessons[i].requestedBy == true){
            return(
              <span className="requestedBy">
                학생
              </span>
            )
          }
          else if(lessons[i].requestedBy == false){
            return(
              <span className="requestedBy">
                코치
              </span>
            )
          }
          else{
            return(
              <span className="requestedBy">
                ?
              </span>
            );
          }
        }
      }
    },
    { name: "createdAt", label: "신청일" }
  ];
  const options = {
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    textLabels: { body: { noMatch: "-" } }
  };

  return (
    <div className="lessons_table">
      <DataTable data={data} columns={columns} options={options} />
    </div>
  );
}
