import React, { Component } from 'react';
import { injectIntl } from "react-intl";
import View from "./View";
import { postInstitution, postUser } from "../../Common/Util/HTTPRequest";
import PopUp from "../../Common/PopUp";
// import sha256 from '../../Common/Util/SHA256';
import Validate from "../../Common/Util/Validate";

const DEFAULT_USER_INPUT = {
  type: "student",
  loginId: "",
  password: "",
  username: "",
  gender: "F",
  certificate: "",
  introduce: "",
  dbType: "일반",
  nickname: "",
  address: "",
  email: "",
  phoneNumber: "",
  maxSerialKeys: null,
  ceoName: "",
  businessNumber: "",
  name: ""
};
export class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: { ...DEFAULT_USER_INPUT },
      warnings: []
    }
  }

  onClickAdd = () => {
    if (!this.validateAllFields()) return;

    const user = this.state.user;
    const params = {
      ...user,
      password: user.password,
      name: user.username
    };
    if (user.type === "instructor") {
      params["introduce"] = user.introduce;
      params["certificate"] = user.certificate;
      params["dbType"] = "프로";
    }

    if(user.type !== "institution"){
      postUser(this.props.user.id, params)
        .then(res => res.json())
        .then(json => {
          if (json.success) {
            PopUp.alert({
              title: "추가되었습니다."
            });
            this.setState({ user: { ...DEFAULT_USER_INPUT }, warnings: [] });

          } else if (json.status === "DuplicateError") {
            this.setState({ warnings: { loginId: "중복 ID가 존재합니다." } });
          } else {
            throw json;
          }
        })
        .catch(err => {
          console.error(err);
          if(err.message === "Unauthorized"){
            PopUp.alert({title: "다시 로그인 해주세요."});
            const { navigate } = this.props;
            sessionStorage.removeItem("loginToken");
            navigate("/");
          }
        });
    }
    else{
      params["name"] = user.name;
      params["maxSerialKeys"] = Number(user.maxSerialKeys);

      postInstitution(this.props.user.id, params)
        .then(res => res.json())
        .then(json => {
          if (json.success) {
            PopUp.alert({
              title: "추가되었습니다."
            });
            this.setState({ user: { ...DEFAULT_USER_INPUT }, warnings: [] });

          } else if (json.status === "DuplicateLoginIdError") {
            this.setState({ warnings: { loginId: "중복된 아이디가 존재합니다." } });
          } else if (json.status === "DuplicateBusinessNumberError") {
            this.setState({ warnings: { loginId: "중복된 사업자등록번호가 존재합니다." } });
          }
          else {
            throw json;
          }
        })
        .catch(err => {
          console.error(err);
          if(err.message === "Unauthorized"){
            PopUp.alert({title: "다시 로그인 해주세요."});
            const { navigate } = this.props;
            sessionStorage.removeItem("loginToken");
            navigate("/");
          }
        });
    }
  }

  validateAllFields() {
    let result = true;
    const warnings = { ...this.state.warnings };
    for (const id in this.state.user) {
      const value = this.state.user[id];
      if(this.state.user.type !== "institution"){
        if (["loginId", "password", "username"].includes(id)) {
          const { result: _result, reason } = Validate[id](value);
          if (!_result) result = false;
          const warning =
            !_result &&
            this.props.intl.formatMessage({
              id: "ID_VALIDATE_FAIL_" + reason.toUpperCase()
            });
          warnings[id] = warning;
        }
      }
      else{
        if(this.state.user.type === "institution"){
          if (["loginId", "password", "name", "email", "phoneNumber", "address", "maxSerialKeys", "ceoName", "businessNumber"].includes(id)) {
            const { result: _result, reason } = Validate[id === "ceoName"? "username" : id](value);
            if (!_result) result = false;
            const warning =
              !_result &&
              this.props.intl.formatMessage({
                id: "ID_VALIDATE_FAIL_" + reason.toUpperCase()
              });
            warnings[id] = warning;
          }
        }
      }
    }
    this.setState({ warnings });
    return result;
  }

  onChangeInputs = e => {
    const { name, value } = e.target;
    let _value = value;
    if (name === "isMarketingAvailable") {
      _value = value === "true"
    }
    this.setState({
      user: {
        ...this.state.user,
        [name]: _value
      }
    })
  }

  render() {
    return (
      <View
        userState={this.state.user}
        warnings={this.state.warnings}
        onClickAdd={this.onClickAdd}
        onChange={this.onChangeInputs}
      />
    )
  }
}

export default injectIntl(AddUser);
