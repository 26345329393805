import React from "react";
import { withLoginInfo } from "../../Common/Util/LoginManager";
import Header from "../../Common/Component/Header";
import Navbar from "../../Common/Component/Navbar";
import { menusForMode, semesterMenus, contentsForMode } from "./DashMap";
import "./index.scss";
import { withRouter } from "../../Common/Util/withRouter";

function View(props) {
  const { params, school, isMasterUser, isSchoolUser } = props;
  const { mode } = params;

  const body = <Body user={props.user}/>;

  return (
    <div className="dashboard">
      <Header />
      {body}
    </div>
  );
}
export default withRouter(withLoginInfo(View));

const UnAuthorized = props => {
  const { onClickLogout, onClickHome } = props;
  return (
    <div className="dashboard_body">
      <div className="dashboard_unauth">
        <div className="dashboard_unauth_title">
          해당 페이지에 접근할 수 있는 권한이 없습니다.
        </div>
        <div className="dashboard_unauth_btns">
          <button
            className="dashboard_unauth_btn dashboard_unauth_btn-login"
            onClick={onClickLogout}
          >
            로그아웃
          </button>
          <button
            className="dashboard_unauth_btn dashboard_unauth_btn-home"
            onClick={onClickHome}
          >
            홈으로
          </button>
        </div>
      </div>
    </div>
  );
};
const NoSchool = props => {
  const { onClickEnterpriseHome, onClickEnterpriseSchools } = props;
  return (
    <div className="dashboard_body">
      <div className="dashboard_unauth">
        <div className="dashboard_unauth_title">
          학교가 선택되지 않았습니다.
        </div>
        <div className="dashboard_unauth_btns">
          <button
            className="dashboard_unauth_btn dashboard_unauth_btn-login"
            onClick={onClickEnterpriseHome}
          >
            기업 홈으로
          </button>
          <button
            className="dashboard_unauth_btn dashboard_unauth_btn-enterprise"
            onClick={onClickEnterpriseSchools}
          >
            학교 선택하기
          </button>
        </div>
      </div>
    </div>
  );
};
const Body = withRouter(function (props) {
  const { mode, menu, semesterId } = props.params;
  const menus = menusForMode[mode];
  const Content = contentsForMode[mode][menu];
  const user = props.user;
  return (
    <div className="dashboard_body">
      <Navbar
        mode={mode}
        menus={menus}
        semesterMenus={semesterMenus}
        menu={menu}
        semesterId={semesterId}
      />
      <div className="dashboard_content">{Content && <Content user={user}/>}</div>
    </div>
  );
});
