import React, { Component } from "react";
import QueryString from "query-string";
import * as request from "../../Common/Util/HTTPRequest";
import Student from "../Student";
import View from "./View";
import "./index.scss";
import { withRouter } from "../../Common/Util/withRouter";
import PopUp from "../../Common/PopUp";

class Swings extends Component {
  constructor(props) {
    super(props);
    this.state = { total: 0, swings: [] };
  }
  componentDidMount() {
    this.loadSwings();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      this.loadSwings();
    }
  }

  loadSwings() {
    const { user } = this.props;
    request
      .getSwings(user.id,)
      .then(res => res.json())
      .then(json => {
        if (json.success) {
          const { total, swings } = json.data;
          this.setState({ total, swings });
        }
        else {
          throw json;
        }
      })
      .catch(err => {
        this.setState({ total: 0, swings: [] });
        console.error(err);
        if(err.message === "Unauthorized"){
          PopUp.alert({title: "다시 로그인 해주세요."});
          const { navigate } = this.props;
          sessionStorage.removeItem("loginToken");
          navigate("/");
        }
      });
  }

  deleteSwings(values) {
    request
      .deleteSwings(this.props.user.id, values)
      .then(res => res.json())
      .then(json => {
        // if (json.success && json.data) {
        if(json){
          PopUp.alert({ title: "수정했습니다." });

          const { navigate } = this.props;
          navigate('/dashboard/master/swings');
        } else {
          throw json;
        }
      })
      .catch(err => {
        console.error(err);
        if(err.message === "Unauthorized"){
          PopUp.alert({title: "다시 로그인 해주세요."});
          const { navigate } = this.props;
          sessionStorage.removeItem("loginToken");
          navigate("/");
        }
      });
  }

  onClickStudent = student => {
    const { navigate, location } = this.props;
    const { search, pathname } = location;
    const query = QueryString.parse(search);
    query.studentId = student.id;
    const queryString = QueryString.stringify(query);
    navigate(pathname + "?" + queryString);
  };

  onClickDelete = e => {
    const swings = this.state.swings;
    let myswingsId = {
      myswingsId: []
    }
    e.data.map(value => 
      myswingsId.myswingsId.push(swings[Number((value.dataIndex))].id)
    )
    this.deleteSwings(myswingsId);
  }

  render() {
    const { location } = this.props;
    const { studentId } = QueryString.parse(location.search);
    if (studentId) {
      return <Student {...this.props} studentId={studentId} />;
    } else {
      const { total, swings } = this.state;
      return (
        <View
          total={total}
          swings={swings}
          onClickStudent={this.onClickStudent}
          onClickDelete={this.onClickDelete}
        />
      );
    }
  }
}

export default withRouter(Swings);
