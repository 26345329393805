import React from "react";
import { injectIntl } from "react-intl";
import PopUp from "../";
import "./index.scss";

const Prompt = injectIntl(function Prompt(props) {
  const { formatMessage } = props.intl;
  const {
    title = formatMessage({ id: "ID_POPUP_DEFAULT_PROMPT_TITLE" }),
    message,
    confirmButtonName = formatMessage({ id: "ID_POPUP_DEFAULT_CONFIRM" }),
    onClickConfirmButton,
    cancelButtonName = formatMessage({ id: "ID_POPUP_DEFAULT_CANCEL" }),
    onClickCancelButton,
    placeholder = formatMessage({ id: "ID_POPUP_DEFAULT_PROMPT_PLACEHOLDER" })
  } = props;
  return (
    <div className="popup_prompt">
      <div className="popup_title">{title}</div>
      {message && <div className="popup_message">{message}</div>}
      <input
        id="popup_input"
        className="popup_input"
        placeholder={placeholder}
      />
      <div className="popup_buttons">
        <div
          className="popup_button popup_button-cancel"
          onClick={onClickCancelButton}
        >
          {cancelButtonName}
        </div>
        <div
          className="popup_button popup_button-confirm"
          onClick={() => {
            if (onClickConfirmButton) {
              const inputElement = document.getElementById("popup_input");
              const inputValue = inputElement ? inputElement.value : "";
              onClickConfirmButton(inputValue);
            }
          }}
        >
          {confirmButtonName}
        </div>
      </div>
    </div>
  );
});

export function prompt(config = {}, options) {
  const {
    title,
    message,
    confirmButtonName,
    onConfirm,
    cancelButtonName,
    onCancel
  } = config;
  PopUp.show(
    <Prompt
      title={title}
      message={message}
      confirmButtonName={confirmButtonName}
      onClickConfirmButton={inputValue => {
        if (onConfirm) onConfirm(inputValue);
        PopUp.dismiss();
      }}
      cancelButtonName={cancelButtonName}
      onClickCancelButton={() => {
        if (onCancel) onCancel();
        PopUp.dismiss();
      }}
    />,
    options
  );
}
