import React from "react";
import moment from "moment";
import DataTable from "../../Common/Component/DataTable";
import "./index.scss";
import { TableRow, TableCell } from "@mui/material";
import JsonView from "react18-json-view";
import 'react18-json-view/src/style.css'

function View(props) {
  const { total } = props;
  return (
    <div className="swings">
      <div className="dashboard_content_title">
        스윙 관리하기 <span>({total}개)</span>
      </div>
      <Table {...props} />
    </div>
  );
}
export default View;

function Table(props) {
  const { swings, onClickStudent, onClickDelete } = props;

  const data = swings.map(swing => ({
    id: swing.id,
    isOpen: swing.isOpen,
    dataJSON: swing.dataJSON,
    videoPath: swing.videoPath,
    note: swing.note,
    createdAt: moment(swing.createdAt).format("YYYY-MM-DD"),
    student: swing.student,
    instructor: swing.instructor,
    name: swing.student? swing.student.user.name : swing.instructor.user.name,
    type: swing.student? swing.student.user.type : swing.instructor.user.type,
    loginId: swing.student? swing.student.user.loginId : swing.instructor.user.loginId
  }));

  const columns = [
    {
      name: "cnt",
      label: "번호",
      options: {
        customBodyRenderLite: i => 
          (
          <span>{i + 1}</span>
        ),
        filterOptions: { fullWidth: true }
      }
    },
    {
      name: "name",
      label: "이름",
      options: {
        customBodyRenderLite: i => (
          <span className="name">
            {data[i].name}
          </span>
        )
      }
    },
    { name: "loginId", label: "로그인 아이디"},
    { 
      name: "isOpen", 
      label: "공개 여부" ,
      options: {
        customBodyRenderLite: i => {
          if(swings[i].isOpen == true){
            return (
              <span>공개</span>
            )
          }
          else{
            return (
              <span>비공개</span>
            )
          }
        }
      }
    },
    // { name: "dataJSON", label: "데이터"},
    { 
      name: "note", 
      label: "코멘트",
      options: {
        customBodyRender: (value) => {
          return value || "-";
        }
      }
    },
    { 
      name: "type", 
      label: "구분",
      options: {
        customBodyRenderLite: i => {
          if(data[i].type == true){
            return (
              <span>프로</span>
            )
          }
          else{
            return (
              <span>일반</span>
            )
          }
        }
      }

    },
    { name: "videoPath", label: "영상 경로" },
    { name: "createdAt", label: "생성일" }
  ];
  const options = {
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: false,
    textLabels: { body: { noMatch: "-" } },
    onRowsDelete: onClickDelete,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <TableRow>
          <TableCell colSpan={8000} className="expandRows">
            <JsonView 
              src={JSON.parse(data[rowMeta.dataIndex].dataJSON).data} 
              displaySize={true}
              collapsed={1}
              enableClipboard={false}
            />
          </TableCell>
        </TableRow>
      );
    },
  };

  // const options = {
  //   filterType: "dropdown",
  //   viewColumns: false,
  //   filter: true,
  //   // selectableRowsHideCheckboxes: true,
  //   textLabels: { body: { noMatch: "-" } }
  // };

  return (
    <div className="swings_table">
      <DataTable data={data} columns={columns} options={options} />
    </div>
  );
}
