import React from "react";
import { injectIntl } from "react-intl";
import PopUp from "../";
import "./index.scss";

const ClassInfo = injectIntl(function({
  intl: { formatMessage },
  title = formatMessage({ id: "ID_POPUP_DEFAULT_CLASS_INFO_TITLE" }),
  message,
  link,
  buttonName = formatMessage({ id: "ID_POPUP_DEFAULT_CONFIRM" }),
  onClickButton
}) {
  return (
    <div className="popup_classInfo">
      <div className="popup_title">{title}</div>
      {message && (
        <div className="popup_message" style={{ textAlign: "left" }}>
          {message}
        </div>
      )}
      {link && (
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "underline" }}
        >
          {formatMessage({ id: "ID_POPUP_DEFAULT_CLASS_INFO_MONITORING_LINK" })}
        </a>
      )}
      <div className="popup_buttons">
        <div
          className="popup_button popup_button-confirm"
          onClick={onClickButton}
        >
          {buttonName}
        </div>
      </div>
    </div>
  );
});

export function classInfo(config = {}, options) {
  const { title, message, link, buttonName, onClickButton } = config;
  PopUp.show(
    <ClassInfo
      title={title}
      message={message}
      link={link}
      buttonName={buttonName}
      onClickButton={() => {
        if (onClickButton) onClickButton();
        PopUp.dismiss();
      }}
    />,
    { ...options, dismissOverlay: false }
  );
}
