import React from "react";
import Container from "./Container";
import { Provider } from "react-redux";
import store from "../../Common/Store";

const MasterHome = props => {
  return (
    <Provider store={store}>
      <Container {...props} />
    </Provider>
  );
};
export default MasterHome;
