import React from "react";
import moment from "moment";
import DataTable from "../../Common/Component/DataTable";
import "./index.scss";
import { type } from "@testing-library/user-event/dist/type";

function View(props) {
  const { total } = props;
  return (
    <div className="institutions">
      <div className="dashboard_content_title">
        기관 관리하기 <span>({total}명)</span>
      </div>
      <Table {...props} />
    </div>
  );
}
export default View;

function Table(props) {
  const { institutions, onClickInstitution } = props;

  let count = 0;
  const data = institutions.map(institution => (
    count = count + 1,
    {
      id: institution.id,
      isApproved: institution.isApproved,
      name: institution.name,
      ceoName: institution.ceoName,
      maxSerialKeys: institution.maxSerialKeys,
      address: institution.address,
      email: institution.email,
      phoneNumber: institution.phoneNumber,
      businessNumber: institution.businessNumber,
      loginId: institution.admin.loginId,
      createdAt: moment(institution.createdAt).format("YYYY-MM-DD"),
      count: count
  }));

  const columns = [
    { name: "count", label: "번호" },
    {
      name: "loginId",
      label: "아이디",
      options: {
        customBodyRenderLite: i => (
          <span
            className="loginId"
            onClick={() => {
              onClickInstitution(institutions[i]);
            }}
          >
            {institutions[i].admin.loginId}
          </span>
        )
      }
    },
    { name: "ceoName", label: "대표자" },
    { 
      name: "isApprove", 
      label: "상태",
      options: {
        customBodyRenderLite: i => {
          if(data[i].isApproved == true){
            return(
              <span>승인</span>
            )
          }
          else if(data[i].isApproved == false){
            return(
              <span>거절</span>
            )
          }
          else{
            return(
              <span>대기</span>
            )
          }
        }
      }
    },
    { name: "name", label: "기관 이름" },
    { name: "address", label: "기관 주소" },
    { 
      name: "businessNumber", 
      label: "사업자등록번호",
      options: {
        customBodyRenderLite: i => (
          <span>{data[i].businessNumber.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3")}</span>
        )
      } 
    },
    { 
      name: "phoneNumber", 
      label: "전화번호",
      options: {
        customBodyRenderLite: i => (
          <span>{data[i].phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")}</span>
        )
      } 
    },
    { name: "email", label: "이메일" },
    { name: "maxSerialKeys", label: "설치 수" },
    { name: "createdAt", label: "가입일" }
  ];
  const options = {
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    textLabels: { body: { noMatch: "-" } },
    setCellProps: () => ({
      style: {
        textAlign: 'center',
      }
    })
  };

  return (
    <div className="Institutions_table">
      <DataTable data={data} columns={columns} options={options} style={{textAlign: 'center'}} />
    </div>
  );
}
