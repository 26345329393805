import React, { Component } from "react";
import { connect, Provider } from "react-redux";
import * as userActions from "../Store/Reducer/user";
import store from "../Store";
import * as request from "./HTTPRequest";

export const withLoginTry = WrappedComponent => {
  const Provided = connect(
    state => ({ user: state.user }),
    {
      updateUser: userActions.updateUser
    }
  )(
    class extends Component {
      constructor(props) {
        super(props);
        this.state = { didTry: false };
      }
      componentDidMount() {
        this.tryLoginByToken();
      }

      tryLoginByToken() {
        if (!this.sessionToken) {
          this.setState({ didTry: true });
          return;
        }
        request
          .loginByToken({ token: this.sessionToken })
          .then(res => res.json())
          .then(json => {
            if (json.status === "success") {
              const { admin, token } = json.data;
              this.props.updateUser(admin)
              sessionStorage.setItem("loginToken", token);
            }
            else if(json.error == "Forbidden"){
              sessionStorage.removeItem("loginToken");
            } 
            else {
              console.error(json);
            }
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => {
            this.setState({ didTry: true });
          });
      }
      get sessionToken() {
        return sessionStorage.getItem("loginToken");
      }

      render() {
        const { didTry } = this.state;
        if (didTry) {
          return <WrappedComponent {...this.props} />;
        } else {
          return <></>;
        }
      }
    }
  );

  return function(props) {
    return (
      <Provider store={store}>
        <Provided {...props} />
      </Provider>
    );
  };
};

export const withLoginInfo = WrappedComponent => {
  class LoginInfo extends Component {
    login = (params, options = {}) => {
      const { onsuccess, onerror } = options;
      request
        .login({ ...params })
        .then(res => res.json())
        .then(json => {
          if (json.status === "success") {
            const { admin, token } = json.data;
            sessionStorage.setItem("loginToken", token);
            this.props.updateUser(admin)
            if (onsuccess) onsuccess();
          } 
          else {
            console.error(json);
            if (onerror) onerror(json);
          }
        })
        .catch(err => {
          console.error(err);
          if (onerror) onerror(err);
        });
    };
    logout = () => {
      sessionStorage.removeItem("loginToken");
      this.props.clearUser();
    };

    get isLoggedIn() {
      return !!this.props.user?.id;
    }
    get isMasterUser() {
      return this.props.user.type === "master";
    }
    get isEnterpriseUser() {
      return this.props.user.type === "enter";
    }
    get isSchoolUser() {
      return this.props.user.type === "normal";
    }
    get isInstitutionUser() {
      return this.props.user.type === "institution";
    }

    render() {
      return (
        <WrappedComponent
          login={this.login}
          logout={this.logout}
          isLoggedIn={this.isLoggedIn}
          isMasterUser={this.isMasterUser}
          isEnterpriseUser={this.isEnterpriseUser}
          isSchoolUser={this.isSchoolUser}
          {...this.props}
        />
      );
    }
  }

  const Provided = connect(
    state => ({ user: state.user }),
    {
      updateUser: userActions.updateUser,
      clearUser: userActions.clearUser
    }
  )(LoginInfo);

  return function(props) {
    return (
      <Provider store={store}>
        <Provided {...props} />
      </Provider>
    );
  };
};
