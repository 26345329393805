import React from "react";
import Field from "../../Common/Component/Field";
import "./index.scss";

function View(props) {
  const {
    loginId,
    password,
    ceoName,
    name,
    address,
    businessNumber,
    phoneNumber,
    email,
    isApproved,
    maxSerialKeys,
    createdAt,
    onChangeField,
    onClickEdit,
    onClickCancel,
    warnings,
    onChangeIsApproved
  } = props;

  return (
    <div className="institution">
      <div className="dashboard_content_title">기관 상세정보</div>
      <div className="institution_fields">
        <Field.Input id="loginId" title="아이디" value={loginId} fixed />
        <Field.Input
          id="password"
          title="비밀번호"
          value={password}
          onChange={onChangeField}
          warning={warnings.password}
        />
        <Field.Input
          id="ceoName"
          title="대표자"
          value={ceoName}
          onChange={onChangeField}
          warning={warnings.ceoName}
        />
        <Field.Input
          id="name"
          title="기관 이름"
          value={name}
          onChange={onChangeField}
          warning={warnings.name}
        />
        <Field.Input
          id="address"
          title="기관 주소"
          value={address}
          onChange={onChangeField}
          warning={warnings.address}
        />
        <Field.Input
          id="businessNumber"
          title="사업자등록번호"
          value={businessNumber}
          onChange={onChangeField}
          warning={warnings.businessNumber}
        />
        <Field.Input
          id="phoneNumber"
          title="전화번호"
          value={phoneNumber}
          onChange={onChangeField}
          warning={warnings.phoneNumber}
        />
        <Field.Input
          id="email"
          title="이메일"
          value={email}
          onChange={onChangeField}
          warning={warnings.email}
        />
        <Field.Input
          id="maxSerialKeys"
          title="설치 수"
          type="number"
          value={maxSerialKeys}
          onChange={onChangeField}
          warning={warnings.maxSerialKeys}
        />
        <Field.Input id="createdAt" title="가입일시" value={createdAt} fixed />
        <section>
          <div className="title">
            승인
          </div>
          <div className="content">
            <label>
              <input
                type="radio"
                name="isApproved"
                value="true"
                checked={isApproved == "true"}
                onChange={onChangeIsApproved}
              />
              승인
            </label>
            <label>
              <input
                type="radio"
                name="isApproved"
                value="false"
                checked={isApproved == "false"}
                onChange={onChangeIsApproved}
              />
              거절
            </label>
          </div>
        </section>
        <button className='btn_edit' onClick={onClickEdit}>수정하기</button>
        <button className='btn_cancel' onClick={onClickCancel}>돌아가기</button>
      </div>
    </div>
  );
}
export default View;
